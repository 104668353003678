import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Link from 'router/Link';
import { AppStore, RootState } from 'reducers';
import * as packsActions from 'actions/packs';
import { AdyenResultHandler } from 'components/Payment';
import logger from 'utils/logger';
import { isTimestampInFuture } from 'utils/time';
import { ENTITLEMENT_TYPES } from 'utils/constants';
import { Box, PrimaryButton, Heading, Text, Metadata } from 'components';
import { Discount, EntitledPack, EntitlementStatus, Offer } from 'types';
import SettingsContainer from '../SettingsContainer';
import Pack from './Pack';

const SUPPORTED_ENTITLEMENT_TYPES = [
  ENTITLEMENT_TYPES.SUBSCRIBE,
  ENTITLEMENT_TYPES.PASS,
  ENTITLEMENT_TYPES.THIRDPARTY,
];

function SubscriptionView() {
  const {
    offers,
    entitlements,
    discounts,
    withPayment,
    isSignUpViaMovistar,
  } = useSelector(
    (
      {
        packs,
        settings,
        user,
      }: RootState,
    ): {
      offers: Offer[],
      entitlements: EntitledPack[],
      discounts: Discount[],
      withPayment: boolean,
      isSignUpViaMovistar: boolean,
    } => ({
      offers: packs.offers,
      entitlements: packs.entitled.filter(
        entitlement => SUPPORTED_ENTITLEMENT_TYPES.includes(entitlement.__typename)
          && (
            [EntitlementStatus.active, EntitlementStatus.expired].includes(entitlement.status)
            || (
              entitlement.status === EntitlementStatus.cancelled
              && entitlement.endDate
              && isTimestampInFuture(entitlement.endDate.valueOf())
            )
          ),
      ),
      discounts: packs.discounts,
      withPayment: !!settings.features.payment,
      isSignUpViaMovistar: user.isSignUpViaMovistar,
    }),
    shallowEqual,
  );

  const renderSubscriptionChoiceButton = () => {
    const ownedPackIds = entitlements.map((item: EntitledPack) => item.id);
    const newPacks = offers.filter((offer: Offer) => !ownedPackIds.includes(offer.id));

    if (!newPacks.length || !withPayment) {
      logger.info('Subscription buttons not displayed because no purchasable packs were found or payment is disabled');
      return null;
    }

    const paymentNextStep = newPacks.length === 1 ? {
      name: 'checkout-confirmation',
      query: {
        packageId: newPacks[0].id,
      },
    } : {
      name: 'checkout',
    };

    if (!entitlements.length) {
      return (
        <Box mt="xlarge">
          <PrimaryButton
            fixedWidth
            variant="brand"
            {...(isSignUpViaMovistar
              ? { disabled: true }
              : { to: paymentNextStep, as: Link }
            )}
          >
            <Text id="startSubscription" />
          </PrimaryButton>
        </Box>
      );
    }

    return (
      <PrimaryButton
        fixedWidth
        variant="brand"
        {...(isSignUpViaMovistar
          ? { disabled: true }
          : { to: { name: 'checkout' }, as: Link }
        )}
      >
        <Text id="subscriptionChoice" />
      </PrimaryButton>
    );
  };

  const packs = entitlements.map((pack: EntitledPack) => (
    <Box key={pack.id} mt="xxxlarge" mb="medium">
      <Pack
        pack={pack}
        discount={discounts.find((item: Discount) => item.discount.pack === pack.id)}
      />
    </Box>
  ));

  return (
    <SettingsContainer maxWidth="38rem">
      <Metadata title="subscription" />

      <Heading
        id="subscription"
        letterSpacing="2.3px"
        mb="small"
      />

      <Box my="medium">
        <AdyenResultHandler />
      </Box>

      <Box my="medium">
        {entitlements.length > 0 ? (
          <Text
            id="subscription.activeSubscriptionDisclaimer"
          />
        ) : (
          <Text
            id="subscription.noOffers"
          />
        )}
      </Box>

      <Box mt="large">
        {renderSubscriptionChoiceButton()}
      </Box>

      {packs}

    </SettingsContainer>
  );
}

export async function initSubscriptionView(store: AppStore): Promise<void> {
  const result = await Promise.all([
    store.dispatch(packsActions.fetchPacks()),
    store.dispatch(packsActions.fetchEntitledPacks()),
  ]).catch((err) => {
    console.error('Error initializing', err);
  });
  await store.dispatch(packsActions.fetchWinbacks(result?.[1]));
}

export default React.memo(SubscriptionView);
