import * as React from 'react';
import {
  LogoutAllDevicesModal,
  Box,
  Text,
  Heading, SecondaryButton,
} from 'components';
import { useDispatch } from 'react-redux';
import { push } from 'router/actions';

function LogoutAllDevicesSection() {
  const [showLogoutAllDevicesModal, setShowLogoutAllDevicesModal] = React.useState(false);
  const dispatch = useDispatch();

  const onClick = () => {
    setShowLogoutAllDevicesModal(true);
  };

  const onLogoutAllDevicesSuccess = () => {
    setShowLogoutAllDevicesModal(false);
    dispatch(push({
      name: 'log-out',
    }));
  };

  const onLogoutAllDevicesClose = () => {
    setShowLogoutAllDevicesModal(false);
  };

  return (
    <>
      <Heading
        id="appSettings.logoutAllDevicesHeading"
        fontSize="sectionHeading"
        mb="medium"
      />

      <Box mb="large">
        <Text
          id="appSettings.logoutAllDevicesMessage"
          whiteSpace="pre-wrap"
          color="secondary"
          fontSize="small"
        />
      </Box>

      <Box mb="xlarge">
        <SecondaryButton
          variant="white"
          onClick={onClick}
        >
          <Text id="appSettings.logoutAllDevicesBtn" />
        </SecondaryButton>

        {showLogoutAllDevicesModal && (
          <LogoutAllDevicesModal
            onSuccess={onLogoutAllDevicesSuccess}
            onClose={onLogoutAllDevicesClose}
          />
        )}
      </Box>
    </>
  );
}

export default React.memo(LogoutAllDevicesSection);
