import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import ModalContainer from 'components/Modal/ModalContainer';
import { Box, PrimaryButton, SecondaryButton, Text } from 'components';
import { ModalHeading } from 'components/Modal/ModalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useLogoutAllDevicesMutation } from './logoutAllDevices.generated';
import logger from 'utils/logger';
import { addMessage } from 'actions/messages';

const LogoutAllDevicesModal = (props) => {
  const {
    onSuccess,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const brandName = useSelector(state => state.settings.brand.name);
  const [logoutAllDevicesMutation, { loading, error }] = useLogoutAllDevicesMutation();

  const showError = (err) => {
    logger.warn('Logout all devices request failed with error', err);
    dispatch(addMessage({ contentId: 'failedMessage' }));
    onClose();
  };

  const onLogoutAllDevices = async () => {
    try {
      const result = await logoutAllDevicesMutation();

      if (result.errors) {
        throw new Error(result.errors[0]?.message);
      }

      onSuccess();
    } catch (e) {
      showError(e);
    }
  };

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  const onCancel = () => {
    if (loading) return;
    onClose();
  };

  return (
    <Modal onDismiss={onClose}>
      <ModalContainer
        onClickClose={onClose}
        withWidth="medium"
        withShadow
        dark
      >
        <Box column>
          <Box my="medium">
            <ModalHeading id="appSettings.logoutAllDevicesModalHeader" />
          </Box>

          <Box column>
            <Box
              mt="large"
              mb="large"
            >
              <Text
                id="appSettings.logoutAllDevicesModalInfo"
                values={{ brandName }}
                align="center"
              />
            </Box>

            <Box
              alignItems="center"
              spaceBetween
              wrap
              columnGap="medium"
              fullWidth
            >
              <Box mt="medium" xs-mx="auto">
                <SecondaryButton
                  variant="white"
                  minWidth="15rem"
                  onClick={onCancel}
                  disabled={loading}
                >
                  <Text id="appSettings.logoutAllDevicesModalBtnCancel" />
                </SecondaryButton>
              </Box>

              <Box mt="medium" xs-mx="auto">
                <PrimaryButton
                  variant="brand"
                  minWidth="15rem"
                  onClick={onLogoutAllDevices}
                  showSpinner={loading}
                >
                  <Text id="appSettings.logoutAllDevicesModalBtnOk" />
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

LogoutAllDevicesModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LogoutAllDevicesModal;
