// @ts-nocheck
import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
export type TContentListPlayableFragment_BroadcastPlayable_Fragment = { __typename?: 'BroadcastPlayable', id: string, kind: Types.TPlayableKind, startTimeUtc: number, watchOffset?: number | null, watched: boolean, duration?: number | null, channel?: { __typename?: 'Channel', title?: string | null, id: string, logoDark?: string | null, logoLight?: string | null } | null, catchup: { __typename?: 'Catchup', from: number, to: number } };

export type TContentListPlayableFragment_ChannelPlayable_Fragment = { __typename?: 'ChannelPlayable', id: string, kind: Types.TPlayableKind, duration?: number | null };

export type TContentListPlayableFragment_LiveEventPlayable_Fragment = { __typename?: 'LiveEventPlayable', id: string, kind: Types.TPlayableKind, startTimeUtc: number, catchupStop?: any | null };

export type TContentListPlayableFragment_VodPlayable_Fragment = { __typename?: 'VodPlayable', id: string, kind: Types.TPlayableKind, watchOffset?: number | null, watched: boolean, duration?: number | null };

export type TContentListPlayableFragmentFragment = TContentListPlayableFragment_BroadcastPlayable_Fragment | TContentListPlayableFragment_ChannelPlayable_Fragment | TContentListPlayableFragment_LiveEventPlayable_Fragment | TContentListPlayableFragment_VodPlayable_Fragment;

export type TContentListBroadcastPlayableFragmentFragment = { __typename?: 'BroadcastPlayable', id: string, kind: Types.TPlayableKind, startTimeUtc: number, watchOffset?: number | null, watched: boolean, duration?: number | null, channel?: { __typename?: 'Channel', title?: string | null, id: string, logoDark?: string | null, logoLight?: string | null } | null, catchup: { __typename?: 'Catchup', from: number, to: number } };

export type TContentListChannelPlayableFragmentFragment = { __typename?: 'ChannelPlayable', id: string, kind: Types.TPlayableKind, duration?: number | null };

export type TContentListVodPlayableFragmentFragment = { __typename?: 'VodPlayable', id: string, kind: Types.TPlayableKind, watchOffset?: number | null, watched: boolean, duration?: number | null };

export type TContentListLiveEventPlayableFragmentFragment = { __typename?: 'LiveEventPlayable', id: string, kind: Types.TPlayableKind, startTimeUtc: number, catchupStop?: any | null };

export type TViewableTagFragmentFragment = { __typename?: 'ViewableTagToRender', title?: string | null, translationKey: string, values: Array<string>, searchable: boolean };

export type TContentListEntitlementFragment_EntitlementSubscribeType_Fragment = { __typename: 'EntitlementSubscribeType', id?: string | null };

export type TContentListEntitlementFragment_EntitlementBuyType_Fragment = { __typename: 'EntitlementBuyType', id?: string | null };

export type TContentListEntitlementFragment_EntitlementDefaultType_Fragment = { __typename: 'EntitlementDefaultType', id?: string | null };

export type TContentListEntitlementFragment_EntitlementPassType_Fragment = { __typename: 'EntitlementPassType', id?: string | null };

export type TContentListEntitlementFragment_EntitlementPurchaseType_Fragment = { __typename: 'EntitlementPurchaseType', id?: string | null };

export type TContentListEntitlementFragment_EntitlementRentType_Fragment = { __typename: 'EntitlementRentType', id?: string | null };

export type TContentListEntitlementFragment_EntitlementThirdPartyType_Fragment = { __typename: 'EntitlementThirdPartyType', id?: string | null };

export type TContentListEntitlementFragmentFragment = TContentListEntitlementFragment_EntitlementSubscribeType_Fragment | TContentListEntitlementFragment_EntitlementBuyType_Fragment | TContentListEntitlementFragment_EntitlementDefaultType_Fragment | TContentListEntitlementFragment_EntitlementPassType_Fragment | TContentListEntitlementFragment_EntitlementPurchaseType_Fragment | TContentListEntitlementFragment_EntitlementRentType_Fragment | TContentListEntitlementFragment_EntitlementThirdPartyType_Fragment;

export const ContentListBroadcastPlayableFragmentFragmentDoc = gql`
    fragment ContentListBroadcastPlayableFragment on BroadcastPlayable {
  id
  kind
  channel {
    id: magineId
    title
    logoDark: image(type: "logo-dark")
    logoLight: image(type: "logo-light")
  }
  startTimeUtc
  watchOffset
  watched
  duration
  catchup {
    from
    to
  }
}
    `;
export const ContentListChannelPlayableFragmentFragmentDoc = gql`
    fragment ContentListChannelPlayableFragment on ChannelPlayable {
  id
  kind
  duration
}
    `;
export const ContentListVodPlayableFragmentFragmentDoc = gql`
    fragment ContentListVODPlayableFragment on VodPlayable {
  id
  kind
  watchOffset
  watched
  duration
}
    `;
export const ContentListLiveEventPlayableFragmentFragmentDoc = gql`
    fragment ContentListLiveEventPlayableFragment on LiveEventPlayable {
  id
  kind
  startTimeUtc
  catchupStop
}
    `;
export const ContentListPlayableFragmentFragmentDoc = gql`
    fragment ContentListPlayableFragment on Playable {
  ...ContentListBroadcastPlayableFragment
  ...ContentListChannelPlayableFragment
  ...ContentListVODPlayableFragment
  ...ContentListLiveEventPlayableFragment
}
    ${ContentListBroadcastPlayableFragmentFragmentDoc}
${ContentListChannelPlayableFragmentFragmentDoc}
${ContentListVodPlayableFragmentFragmentDoc}
${ContentListLiveEventPlayableFragmentFragmentDoc}`;
export const ViewableTagFragmentFragmentDoc = gql`
    fragment ViewableTagFragment on ViewableTagToRender {
  title
  translationKey
  values
  searchable
}
    `;
export const ContentListEntitlementFragmentFragmentDoc = gql`
    fragment ContentListEntitlementFragment on EntitlementInterfaceType {
  __typename
  id: offerId
}
    `;