import packageJson from '../../package.json';
import { UnauthorizedError } from 'utils/errors';

const { version } = packageJson;

// small timeout on server to not slow down SSR
// big timeout on client to decrease failures in slow networks
const timeoutMs = global.__SERVER__ ? 12000 : 60000;

const errorsToRetry = [
  400,
  500,
  502,
  503,
  504,
];

const fetchWithRetry = (url, options = {}, retries) => fetch(url, options)
  .then((res) => {
    if (res.ok) {
      return res;
    }
    if (res.status === 401) {
      throw new UnauthorizedError();
    }
    if (retries > 0 && errorsToRetry.includes(res.status)) {
      return fetchWithRetry(url, options, retries - 1);
    }
    return res;
  });

export function fetchWithTimeout(url, options, retries = 3) {
  const controller = new AbortController();
  const timeout = setTimeout(() => controller.abort(), timeoutMs);
  const headers = options?.headers || {};

  const updatedOptions = {
    ...(options || {}),
    headers: {
      ...headers,
      ...(headers.authorization || headers.Authorization ? { 'cache-control': 'no-cache' } : {}),
      'mdm-client-name': 'web',
      'mdm-client-version': version,
    },
  };

  return fetchWithRetry(url, updatedOptions, retries).finally(() => clearTimeout(timeout));
}
