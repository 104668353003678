import { useEffect, useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import * as Cookies from 'js-cookie';
import * as authActions from 'actions/auth';
import * as userActions from 'actions/user';
import { PLATFORMS } from 'utils/constants';
import logger from 'utils/logger';
import { sessionStorage } from 'utils/storage';

/**
 * useAnonymousUser - A hook that creates an anonymous user on mount
 * This hook handles creating/fetching an anonymous user
 * It is controlled by a feature flag (settings.features.anonymousUser.enabled)
 * 
 * @returns {Object} Object containing isLoading state and user data
 */
function useAnonymousUser(canCreateAnonymousUser = false) {
  const dispatch = useDispatch();
  const store = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  
  // Use both ref and sessionStorage to ensure the request is only fired once
  // The ref prevents multiple calls within the same session
  // The sessionStorage prevents multiple calls across page refreshes
  const requestFiredRef = useRef(false);
  
  // Create anonymous user on component mount - using empty dependency array
  // to ensure this only runs once when the component mounts
  useEffect(() => {
    if (!canCreateAnonymousUser) {
      logger.info('Anonymous user creation feature is disabled, skipping');
      setIsLoading(false);
      return;
    }
    // Check both the ref and sessionStorage to see if we've already attempted to create a user
    const hasAttemptedCreation = sessionStorage.getItem('anonymousUserCreationAttempted') === 'true';
    
    // Skip if the request has already been fired (either in this session or a previous one)
    if (requestFiredRef.current || hasAttemptedCreation) {
      logger.info('Anonymous user creation already attempted, skipping');
      setIsLoading(false);
      return;
    }

    // Mark that we've attempted to create a user - do this immediately
    // to prevent any possibility of multiple calls
    requestFiredRef.current = true;
    sessionStorage.setItem('anonymousUserCreationAttempted', 'true');
    
    const createAnonymousUser = async () => {
      // Get all required state inside the effect to avoid dependencies
      const state = store.getState();
      
      // Use object destructuring as recommended by ESLint
      const { 
        settings: { features: { anonymousUser: anonymousUserFeature } }, 
        auth: { isLoggedIn },        
      } = state;
      
      // Check if user is already logged in or if the feature is disabled
      if (!anonymousUserFeature?.enabled || isLoggedIn) {
        setIsLoading(false);
        
        // If already logged in, we don't need to create an anonymous user
        if (isLoggedIn) {
          logger.info('User already logged in, skipping anonymous user creation');
          // Get the current user data
          const userData = state.user;
          setUser(userData);
        }
        return;
      }
      
      // Check if user is already logged in as an anonymous user
      // This is an additional check to prevent creating multiple anonymous users
      const isAnonymousUser = Cookies.get('isAnonymousUser') === 'true';
      const hasUserId = !!Cookies.get('userId');
      const hasToken = !!Cookies.get('token');
      
      if (isAnonymousUser && hasUserId && hasToken) {
        logger.info('User already has anonymous user cookies, skipping creation');
        setIsLoading(false);
        
        // Ensure the auth state is updated with the cookie values
        const userId = Cookies.get('userId');
        const token = Cookies.get('token');
        
        if (userId && token) {
          await dispatch(authActions.setAuth(token, userId, true));
          // Get the current user data after auth is set
          await dispatch(userActions.getUser());
          const updatedState = store.getState();
          setUser(updatedState.user);
        }
        
        return;
      }
            
      // Get device information inside the effect
      const devicePlatformState = state.router.location.params?.platform;
      let deviceTypeState = 'web';

      if (devicePlatformState) {
        if (devicePlatformState === PLATFORMS.MOVISTAR) {
          deviceTypeState = 'stb';
        } else {
          deviceTypeState = 'smarttv';
        }
      }
      
      const browserName = (state.common.browser ?? '').toLowerCase();
      const browserModel = (state.common.description ?? '').toLowerCase();
      const deviceType = state.router.query?.type ?? deviceTypeState;
      const qrCode = state.router.query?.qrCode;
      
      // Information about the device/browser
      const signupInfo = {
        devicePlatform: devicePlatformState ?? browserName,
        deviceModel: devicePlatformState ? state.router.query?.model : browserModel,
        deviceType,
        qrCode,
        url: typeof window !== 'undefined' ? window.location?.href : undefined,
        proxyDeviceType: devicePlatformState ? 'web' : undefined,
      };

      try {
        logger.info('Creating anonymous user...');
        
        // Use the createAnonymousUser action without captcha token
        await dispatch(authActions.createAnonymousUser(signupInfo));
        
        // Get user information
        await dispatch(userActions.getUser());
        
        logger.info('Anonymous user created successfully');
        
        // Set the user data from the updated state
        const updatedState = store.getState();
        setUser(updatedState.user);
                                
      } catch (err) {
        logger.error('Failed to create anonymous user', err);
        sessionStorage.removeItem('anonymousUserCreationAttempted');
        throw new Error('Failed to create anonymous user');
      } finally {
        setIsLoading(false);
      }
    };

    createAnonymousUser();
  }, []); // Empty dependency array ensures this only runs once when component mounts

  return { isLoading, user };
}

export default useAnonymousUser;
