import {
  WatchContext,
  MENU_ITEM_TYPES,
  MENU_LINK_TARGET_TYPES,
} from 'utils/constants';
import logger from 'utils/logger';
import {
  doLogout,
  redirectWatchContext,
  requireLogin,
  requireLoginStrict,
  requireLogout,
  requirePayment,
  rememberLastLocation,
  rememberPromoCode,
  rememberPackageId,
  requireCreateAccountEnabled,
  validatePlatform,
  requireLoginForAddingToWatchlist,
  redirectPlatform,
  requireNotSignUpViaMovistar,
  requireNonAnonymousUser,
  requireAnonRegEnabled,
} from 'utils/routing/middleware';
import withProps from 'utils/withProps';

import GeneralErrorPage from 'components/ErrorPage/GeneralErrorPage';

import ContentListView from 'views/ContentListView/ContentListView';
import EpgView, { initEpgView } from 'views/EpgView/EpgView';
import CollectionView from 'views/CollectionView/CollectionView';
import GenresView from 'views/GenresView/GenresView';
import SearchView from 'views/SearchView/SearchView';
import WatchView, { initWatchView } from 'views/WatchView/WatchView';
import WebView from 'views/WebView/WebView';

import OfferSelectionView from 'views/Checkout/OfferSelectionView';
import SelectOffersChange from 'views/Checkout/OfferSelectionView/SelectOffersChange';
import SelectOffersOfMedia from 'views/Checkout/OfferSelectionView/SelectOffersOfMedia';
import ReceiptView, { initReceiptView } from 'views/Checkout/ReceiptView';
import CheckoutView, { initCheckoutConfirmation } from 'views/Checkout/CheckoutView';
import Upgrade, { initUpgrade } from 'views/Checkout/CheckoutView/Upgrade';

import NotFoundView from 'views/NotFoundView/NotFoundView';

import LoginView from 'views/LoginView/LoginView';
import CreateAccountView, { initCreateAccountView } from 'views/CreateAccountView/CreateAccountView';
import ForgotPasswordView from 'views/ForgotPasswordView/ForgotPasswordView';
import ResetPasswordView from 'views/ResetPasswordView/ResetPasswordView';
import CreatePasswordView from 'views/CreatePasswordView/CreatePasswordView';

import SubscriptionView, { initSubscriptionView } from 'views/Settings/Subscription/SubscriptionView';
import AccountView from 'views/Settings/Account/AccountView';
import PaymentMethodView, { initPaymentMethodView } from 'views/Settings/Payment/PaymentMethodView';
import PaymentMethodSetupView from 'views/Settings/Payment/PaymentMethodSetupView';
import PaymentMethodSetupConfirmView from 'views/Settings/Payment/PaymentMethodSetupConfirmView';
import TransactionsView from 'views/Settings/Transactions/TransactionsView';
import AppSettingsView from 'views/Settings/AppSettings/AppSettingsView';
import CollectionWebView from 'views/CollectionWebView/CollectionWebView';
import DeviceAuthView from 'views/DeviceAuthView/DeviceAuthView';

const ALWAYS = true;

function moduleFallback(err) {
  logger.warn('failed to load async component module:', err);

  return GeneralErrorPage;
}

async function getPartnerSpecificRoutes(
  settings,
) {
  if (settings.partner === 'sappa') {
    return [
      {
        name: 'create-account',
        path: '/create-account',
        onEnter: [requireCreateAccountEnabled, requireLogout],
        onNavigation: [rememberLastLocation],
        component: await import(/* webpackChunkName: "sappa" */ './_projects/sappa/views/CreateAccountView/CreateAccountView')
          .then(
            module => module.default,
            moduleFallback,
          ),
        enabledIf: ALWAYS,
        pageAnalytics: {
          component: 'Signup',
        },
      },
    ];
  }

  return [];
}

export default async function getRoutes(store, apollo) {
  const { settings } = store.getState();
  const paymentEnabled = !!settings.features.payment;
  const paymentInformationEnabled = !!settings.features.settings.payment;
  const transactionsEnabled = !!settings.features.settings.transactions;
  const subscriptionEnabled = !!settings.features.subscription;
  const forgotPasswordEnabled = !!settings.features.loginMethod.forgotPassword;
  const createPasswordEnabled = !!settings.features.createPasswordMethod;
  const isAccountSettingsEnabled = !!settings.settingsMenu;

  const home = settings.navigation.menu[settings.navigation.defaultIndex];
  if (!home) {
    logger.warn("Routes couldn't determine the home entry in the menu. Check navigation menu on partner config");
  }

  const hasWebViews = !!settings.navigation.menu.find(
    item => item.type === MENU_ITEM_TYPES.link
     && item.linkTarget === MENU_LINK_TARGET_TYPES.webview,
  );

  const routesConfig = [
    ...await getPartnerSpecificRoutes(settings),
    {
      name: 'collectionWebview',
      path: '/webview/:collectionId/:encodedUrl',
      component: CollectionWebView,
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
      layout: {
        hideScroll: true,
      },
      pageAnalytics: {
        component: 'WebView',
      },
    },
    {
      name: 'webview',
      path: '/webview/:id',
      component: WebView,
      onEnter: [requireLogin, requirePayment],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: hasWebViews,
      layout: {
        hideScroll: true,
      },
      pageAnalytics: {
        component: 'WebView',
      },
    },
    {
      name: 'home',
      path: '',
      component: EpgView,
      init: initEpgView,
      onEnter: [requireLoginStrict],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: home.type === 'epg',
      layout: {
        hideFooter: true,
      },
      pageAnalytics: {
        component: 'EPG',
      },
    },
    {
      name: 'epg',
      path: '/epg',
      component: EpgView,
      init: initEpgView,
      onEnter: [requireLoginStrict, requirePayment],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: settings.navigation.menu.find(item => item.type === 'epg'),
      layout: {
        hideFooter: true,
      },
      pageAnalytics: {
        component: 'EPG',
      },
    },
    {
      name: 'home',
      path: '',
      component: withProps(ContentListView, { rootCategoryId: home.identifier }),
      onEnter: [requireLogin, requirePayment],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: home.type === 'contentList',
      pageAnalytics: {
        component: 'CollectionsList',
      },
    },
    {
      name: 'collections',
      path: '/collections/:id',
      component: ContentListView,
      onEnter: [requireLogin, requirePayment],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: settings.navigation.menu.find(item => item.type === 'contentList'),
      pageAnalytics: {
        component: 'CollectionsList',
      },
    },
    // Redirect watch context routes to watch route without context parameter
    ...Object.values(WatchContext).map(context => ({
      name: `watch-${context}`,
      path: `/watch/${context}/:id/:playableId?`,
      onEnter: [redirectWatchContext],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
    })),
    {
      name: 'watch',
      path: '/watch/:id/:playableId?',
      component: WatchView,
      init: initWatchView,
      onEnter: [requireLogin, requirePayment, requireLoginForAddingToWatchlist],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'DetailsPage',
      },
    },
    {
      name: 'log-in',
      path: '/log-in/:platform?',
      component: LoginView,
      onEnter: [requireAnonRegEnabled, requireLogout, redirectPlatform],
      onNavigation: [rememberLastLocation, rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'Login',
      },
    },
    {
      name: 'log-out',
      path: '/log-out',
      onEnter: [requireNonAnonymousUser, doLogout],
      enabledIf: ALWAYS,
    },
    {
      name: 'create-account',
      path: '/create-account/:platform?',
      component: CreateAccountView,
      init: initCreateAccountView,
      onEnter: [requireCreateAccountEnabled, requireLogout],
      onNavigation: [rememberLastLocation, rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'Signup',
      },
    },
    {
      name: 'register',
      path: '/register/:platform?',
      component: CreateAccountView,
      init: initCreateAccountView,
      onEnter: [validatePlatform, requireCreateAccountEnabled, requireLogout],
      onNavigation: [rememberLastLocation, rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'Signup',
      },
    },
    {
      name: 'signup',
      path: '/signup/:platform?',
      component: CreateAccountView,
      init: initCreateAccountView,
      onEnter: [validatePlatform, requireCreateAccountEnabled, requireLogout],
      onNavigation: [rememberLastLocation, rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'Signup',
      },
    },
    {
      name: 'device-auth',
      path: '/link/:platform/:step?',
      onEnter: [validatePlatform, requireLoginStrict],
      onNavigation: [rememberPromoCode, rememberPackageId],
      component: DeviceAuthView,
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'DeviceAuth',
      },
    },
    {
      name: 'forgot-password',
      path: '/forgot-password',
      component: ForgotPasswordView,
      onEnter: [requireAnonRegEnabled, requireLogout],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: forgotPasswordEnabled,
      pageAnalytics: {
        component: 'ForgotPassword',
      },
    },
    {
      name: 'checkout',
      path: '/checkout',
      component: OfferSelectionView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: paymentEnabled,
      pageAnalytics: {
        component: 'PurchaseFlow',
        context: {
          view: 'choice',
        },
      },
    },
    {
      name: 'checkout-media',
      path: '/offers/:id',
      component: SelectOffersOfMedia,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: paymentEnabled,
      pageAnalytics: {
        component: 'PurchaseFlow',
        context: {
          view: 'choice-video',
        },
      },
    },
    {
      name: 'offers-change',
      path: '/offers/change/:id',
      component: SelectOffersChange,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: paymentEnabled,
      pageAnalytics: {
        component: 'PurchaseFlow',
        context: {
          view: 'offers-change',
        },
      },
    },
    {
      name: 'checkout-confirmation',
      path: '/checkout/confirmation',
      component: CheckoutView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode],
      init: initCheckoutConfirmation,
      enabledIf: paymentEnabled,
      pageAnalytics: {
        component: 'PurchaseFlow',
        context: {
          view: 'confirmation',
        },
      },
    },
    {
      name: 'checkout-upgrade',
      path: '/checkout/upgrade/:id/:originalOfferId',
      component: Upgrade,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      init: initUpgrade,
      enabledIf: paymentEnabled,
      pageAnalytics: {
        component: 'PurchaseFlow',
        context: {
          view: 'confirmation-upgrade',
        },
      },
    },
    {
      name: 'purchase-offer',
      path: '/purchase-offer/:id',
      component: CheckoutView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      init: initCheckoutConfirmation,
      enabledIf: paymentEnabled,
      pageAnalytics: {
        component: 'PurchaseFlow',
        context: {
          view: 'confirmation',
        },
      },
    },
    {
      name: 'checkout-receipt',
      path: '/checkout/receipt',
      component: ReceiptView,
      init: initReceiptView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      enabledIf: paymentEnabled,
      pageAnalytics: {
        component: 'PurchaseFlow',
        context: {
          view: 'receipt',
        },
      },
    },
    {
      name: 'reset-password',
      path: '/reset-password/:token',
      component: ResetPasswordView,
      onEnter: [requireAnonRegEnabled],
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'ResetPassword',
      },
    },
    {
      name: 'create-password',
      path: '/create-password/:token',
      component: CreatePasswordView,
      onEnter: [requireAnonRegEnabled, requireLogout],
      enabledIf: createPasswordEnabled,
      pageAnalytics: {
        component: 'CreatePassword',
      },
    },
    {
      name: 'settings-account',
      path: '/settings/account',
      component: AccountView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: isAccountSettingsEnabled,
      pageAnalytics: {
        component: 'Settings',
        context: {
          view: 'account',
        },
      },
    },
    {
      name: 'settings-payment',
      path: '/settings/payment',
      component: PaymentMethodView,
      init: initPaymentMethodView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: paymentInformationEnabled && isAccountSettingsEnabled,
      pageAnalytics: {
        component: 'Settings',
        context: {
          view: 'payment',
        },
      },
    },
    {
      name: 'settings-payment-setup',
      path: '/settings/payment/setup',
      component: PaymentMethodSetupView,
      // do not allow user to change payment method or plans when user sign up via movistar
      onEnter: [requireLoginStrict, requireNonAnonymousUser, requireNotSignUpViaMovistar],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: paymentInformationEnabled && isAccountSettingsEnabled,
      pageAnalytics: {
        component: 'Settings',
        context: {
          view: 'payment-setup',
        },
      },
    },
    {
      name: 'settings-payment-setup-confirm',
      path: '/settings/payment/setup/confirm',
      component: PaymentMethodSetupConfirmView,
      // do not allow user to change payment method or plans when user sign up via movistar
      onEnter: [requireLoginStrict, requireNonAnonymousUser, requireNotSignUpViaMovistar],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: paymentInformationEnabled && isAccountSettingsEnabled,
      pageAnalytics: {
        component: 'Settings',
        context: {
          view: 'payment-setup-confirm',
        },
      },
    },
    {
      name: 'settings-transactions',
      path: '/settings/transactions',
      component: TransactionsView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: transactionsEnabled && isAccountSettingsEnabled,
    },
    {
      name: 'settings',
      path: '/settings/settings',
      component: AppSettingsView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: isAccountSettingsEnabled,
      pageAnalytics: {
        component: 'Settings',
        context: {
          view: 'settings',
        },
      },
    },
    {
      name: 'settings-subscription',
      path: '/settings/subscription',
      component: SubscriptionView,
      init: initSubscriptionView,
      onEnter: [requireLoginStrict, requireNonAnonymousUser],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: subscriptionEnabled && isAccountSettingsEnabled,
      pageAnalytics: {
        component: 'Settings',
        context: {
          view: 'subscription',
        },
      },
    },
    {
      name: 'collection',
      path: '/collection/:id',
      component: CollectionView,
      onEnter: [requireLogin, requirePayment],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'CollectionList',
      },
    },
    {
      name: 'genres',
      path: '/genres/:id',
      component: GenresView,
      onEnter: [requireLogin, requirePayment],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'GenresList',
      },
    },
    {
      name: 'search',
      path: '/search',
      component: SearchView,
      onEnter: [requireLogin, requirePayment],
      onNavigation: [rememberPromoCode, rememberPackageId],
      enabledIf: settings.searchMenu,
      pageAnalytics: {
        component: 'Search',
      },
    },
    {
      name: 'not-found',
      path: '(.*)',
      status: 404,
      component: NotFoundView,
      enabledIf: ALWAYS,
      pageAnalytics: {
        component: 'NotFound',
      },
    },
  ];

  const definedRoutes = new Set();
  const definedPaths = new Set();

  return routesConfig.filter(
    ({ name, path, enabledIf }) => {
      if (!enabledIf) {
        return false;
      }

      if (definedRoutes.has(name)) { // use only first route with specific name
        return false;
      }

      if (definedPaths.has(path)) { // ensure that there are no duplicate paths
        throw new Error(`Duplicate router path "${path}"`);
      }

      definedPaths.add(path);
      definedRoutes.add(name);

      return true;
    },
  ).map(({ onEnter = [], onNavigation = [], layout = {}, init, ...others }) => ({
    // pass through redux store into all onEnter hooks
    onEnter: onEnter.map(middleware => middleware.bind(null, store)),
    onNavigation: onNavigation.map(middleware => middleware.bind(null, store)),

    // pass through redux store into init hook
    init: init ? init.bind(null, store, apollo) : null,

    couldBeInitialView: onEnter.includes(requireLogin),

    layout,

    ...others,
  }));
}
