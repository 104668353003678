import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import logger from 'utils/logger';
import * as authActions from 'actions/auth';
import * as routerActions from 'router/actions';
import * as ApiErrors from '@tvoli/js-api-client/src/errors';
import validate from 'utils/validate';
import {
  PrimaryButton,
  FormattedError,
  Text,
  Box,
  Link,
  Heading,
  PanelContent,
  PanelWrapper,
  Panel,
  Metadata,
} from 'components';
import { I18nContext } from 'components/I18n';
import {
  FormField,
  TextField,
} from 'components/Forms';

function ResetPasswordView() {
  const i18n = useContext(I18nContext);
  const dispatch = useDispatch();
  const [generalError, setGeneralError] = useState(null);
  const {
    token,
    isLoggedIn,
    registerMethods,
    disableRegister,
  } = useSelector(state => ({
    token: state.router.location.params.token,
    isLoggedIn: state.auth.isLoggedIn,
    registerMethods: state.settings.features.registerMethods,
    disableRegister: !state.settings.registerEnabled,
  }));
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit } = methods;

  let passwordInput;
  registerMethods.inputGroups.some(inputGroup => inputGroup.inputFields.find((inputField) => {
    if (inputField.key === 'accessKey') {
      passwordInput = inputField;
      return true;
    }
    return false;
  }));
  const submit = async ({ password }) => {
    setGeneralError(null);
    try {
      await dispatch(authActions.resetPassword(token, password));

      if (isLoggedIn) {
        await dispatch(authActions.logout(true));
      }

      return dispatch(routerActions.push({ name: 'log-in' }));
    } catch (err) {
      if (err instanceof ApiErrors.InvalidInputError) {
        setGeneralError('resetPassword.errLinkExpired');
      }

      logger.error('Failed to reset the password', err);

      setGeneralError('couldntSave');
    }

    return null;
  };

  return (
    <PanelWrapper className="e2e-reset-password">
      <Panel textAlign="center">
        <Metadata title="resetPassword.heading" />

        <Heading id="resetPassword.heading" mb="small" />
        <FormProvider {...methods}>
          <PanelContent
            centered
            as="form"
            onSubmit={handleSubmit(submit)}
            method="POST"
          >
            <FormField
              as={TextField}
              name="password"
              placeholder={i18n.formatText('password')}
              type="password"
              validations={{
                required: validate.required('validate.passRequired'),
                validate: validate.inputFieldValidations(passwordInput?.validations || []),
              }}
            />

            <FormattedError error={generalError} />

            <Box my="medium">
              <PrimaryButton variant="brand" type="submit">
                <Text id="save" />
              </PrimaryButton>
            </Box>

            {!isLoggedIn && !disableRegister && (
              <Link
                id="logIn"
                fontSize="small"
                to={{ name: 'log-in' }}
              />
            )}
          </PanelContent>
        </FormProvider>
      </Panel>
    </PanelWrapper>
  );
}

export default React.memo(ResetPasswordView);
