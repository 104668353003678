import getOfferPriceQuery from 'queries/getOfferPrice.graphql';
import getCampaignQuery from 'queries/getCampaign.graphql';
import { TGetCampaignQuery } from 'queries/getCampaign.generated';
import { TGetOfferPriceMutation } from 'queries/getOfferPrice.generated';
import { graphqlRequest, AppAsyncAction, GraphqlResult } from './helpers';

type Campaign = {
  name: string,
  usps?: string[],
  offerIds: string[],
  bypassPaymentMethod: boolean,
};
export const getCampaign = (promoCode: string): AppAsyncAction<Campaign> => async (dispatch) => {
  const {
    data: { viewer: { campaign } },
  }: { data: TGetCampaignQuery } = await dispatch(graphqlRequest(getCampaignQuery, { promoCode }));

  const offerIds = campaign?.offers
    .edges?.map((edge: any) => edge.node.id) || [];
  const bypassPaymentMethod = !!campaign?.bypassPaymentMethodRegistration;
  const usps = campaign?.usps ?? [];

  return {
    name: campaign?.name || '',
    offerIds,
    usps,
    bypassPaymentMethod,
  };
};

// see https://magine.atlassian.net/browse/MDM-11048 and https://magine.atlassian.net/browse/MDM-11784
export function extractPromocodeValidationError(errorDetails: string): string {
  // need to be refactored to use 'code' instead of comparing strings. wait backend

  if (errorDetails.includes('Campaign is consumed')) {
    return 'promoCode.errorCampaignConsumed';
  }

  if (errorDetails.includes('Limit exceeded')) {
    return 'promoCode.errorLimitExceeded';
  }

  if (errorDetails.includes('Not available in market')) {
    return 'promoCode.errorNotAvailableInMarket';
  }

  if (errorDetails.includes('Campaign is not active')) {
    return 'promoCode.errorCampaignIsNotActive';
  }

  if (errorDetails.includes('Promo code can only be consumed at signup')) {
    return 'promoCode.errorSignupOnly';
  }

  if (errorDetails.includes('This code has already been used')) {
    return 'promoCode.errorCodeConsumed';
  }

  return 'promoCode.notValid';
}

export type PromocodeValidationResultSuccess = {
  valid: true,
  promoCode: string,
  campaignName: string,
  discount: TGetOfferPriceMutation['discount'],
  bypassPaymentMethod: boolean,
  usps?: string[],
};
type PromocodeValidationResult = {
  valid: false,
  errorId: string,
} | PromocodeValidationResultSuccess;

export function validatePromocode(
  offerId: string,
  promoCode: string,
): AppAsyncAction<PromocodeValidationResult> {
  return async (dispatch, getState) => {
    const isPromoCodeAllowed = getState().settings.features.payment?.allowPromoCodes;
    if (!isPromoCodeAllowed) {
      throw new Error('validatePromocode: promo codes are not allowed');
    }

    if (!promoCode) {
      return {
        valid: false,
        errorId: 'promoCode.notValid',
      };
    }

    const campaign = await dispatch(getCampaign(promoCode));

    if (!campaign.offerIds.includes(offerId)) {
      return {
        valid: false,
        errorId: 'promoCode.notValid',
      };
    }

    const {
      data,
      errors,
    }: {
      data: TGetOfferPriceMutation,
      errors: GraphqlResult['errors'],
    } = await dispatch(graphqlRequest(getOfferPriceQuery, {
      offerId,
      code: promoCode,
    }));

    if (errors?.length) {
      return {
        valid: false,
        errorId: extractPromocodeValidationError(JSON.stringify(errors)),
      };
    }

    if (!data.discount) {
      return {
        valid: false,
        errorId: 'promoCode.notValid',
      };
    }

    return {
      valid: true,
      promoCode,
      discount: data.discount,
      campaignName: campaign.name,
      bypassPaymentMethod: campaign.bypassPaymentMethod,
      usps: campaign.usps,
    };
  };
}

type PromocodeToOfferValidationResult = {
  valid: boolean,
  errorId?: string,
};

export function validatePromocodeToOffer(
  offerId: string,
  promoCode: string,
): AppAsyncAction<PromocodeToOfferValidationResult> {
  return async (dispatch, getState) => {
    const isPromoCodeAllowed = getState().settings.features.payment?.allowPromoCodes;
    if (!isPromoCodeAllowed) {
      throw new Error('validatePromocode: promo codes are not allowed');
    }

    if (!promoCode) {
      return {
        valid: false,
        errorId: 'promoCode.notValid',
      };
    }

    const {
      data,
      errors,
    }: {
      data: TGetOfferPriceMutation,
      errors: GraphqlResult['errors'],
    } = await dispatch(graphqlRequest(getOfferPriceQuery, {
      offerId,
      code: promoCode,
    }));

    if (errors?.length) {
      return {
        valid: false,
        errorId: extractPromocodeValidationError(JSON.stringify(errors)),
      };
    }

    if (!data.discount) {
      return {
        valid: false,
        errorId: 'promoCode.notValid',
      };
    }

    return {
      valid: true,
    };
  };
}
