import { getPlayableProp } from 'utils/helpers';
import { ifCatchupIsItAvailable } from 'utils/broadcast';
import { ifLiveEventIsItAvailable } from 'utils/live-event';

export const renderTitle = viewable => (
  viewable.broadcastById?.title
  || viewable.schedule?.['0']?.title
  || viewable.title
);

export function getPageTitle(viewable) {
  if (viewable.broadcastById) {
    return viewable.broadcastById.title || viewable.title;
  }

  return viewable.title;
}

export function getPageDescription(viewable) {
  if (viewable.broadcastById) {
    return viewable.broadcastById.description || viewable.channelDescription;
  }
  // channel description as main. Schedule description as fallback
  return viewable.channelDescription || viewable.description;
}

export function getCanonicalData(location, isShow) {
  return {
    name: location.name,
    params: isShow ? { id: location.params.id } : location.params,
    query: null,
  };
}

function isNotWatchedAndAvail(viewable, currentVideoId) {
  return viewable.id !== currentVideoId
    && !getPlayableProp(viewable, 'watchOffset')
    && !getPlayableProp(viewable, 'watched')
    && viewable.entitlement
    && ifCatchupIsItAvailable(viewable)
    && ifLiveEventIsItAvailable(viewable);
}

export function getNextRecommendation(viewable) {
  let recommendationItem;

  if (viewable.show) { // next recommendation only for last episode-season
    const lastSeason = viewable.seasons[viewable.seasons.length - 1];
    if (lastSeason.seasonNumber !== viewable.seasonNumber) return;

    const lastEpisode = lastSeason.episodes[lastSeason.episodes - 1];
    if (lastEpisode.episodeNumber !== viewable.episodeNumber) return;
  }

  recommendationItem = viewable.related.find(i => isNotWatchedAndAvail(i, viewable.id));

  if (!recommendationItem) {
    viewable.collections.some(
      c => c.viewables.edges.some((i) => {
        if (isNotWatchedAndAvail(i.node, viewable.id)) {
          recommendationItem = i.node;
          return true;
        }
      }),
    );
  }

  return recommendationItem;
}
