import { VIEWABLE_TYPES } from 'utils/constants';
import logger from 'utils/logger';

// We can use TViewableQuery['viewer']['viewable'] from 'queries/viewable.generated';
type Viewable = any; // FIXME

// Business rule: if player rights are not explicitly set all rights defaults to true
const defaultRights = {
  fastForward: true,
  pause: true,
  rewind: true,
};
const viewableDefaultProps = (viewable: Viewable) => ({
  genres: viewable.genres || [],
  offers: viewable.offers || [],
});
const extractWebviews = (webviewsRaw: string | null = null) => {
  const webviews = JSON.parse(webviewsRaw as string);
  if (!webviews) {
    return null;
  }

  // ignore webviews without title MDM-9793
  return webviews.filter((item: any) => item.webviewTitle);
};

/**
 * Turns viewables into a normalized format with a title, description, and poster.
 */
export function normalizeViewable(viewable: Viewable) {
  if (!viewable) {
    return null;
  }

  switch (viewable.__typename) {
    case VIEWABLE_TYPES.Episode:
    case VIEWABLE_TYPES.Movie:
    case VIEWABLE_TYPES.Trailer:
    case VIEWABLE_TYPES.Program: {
      if (!viewable.defaultPlayable) {
        logger.warn('Viewable doesn\'t have a default playable. Skipping', viewable.id, viewable.title);
        return null;
      }
      const playable = viewable.defaultPlayable;

      return {
        ...viewable,
        ...viewableDefaultProps(viewable),
        webviews: extractWebviews(viewable.webview),
        playableId: playable.id,
        rights: defaultRights,
        duration: playable.duration,
        durationHuman: viewable.durationHuman,
      };
    }

    case VIEWABLE_TYPES.Show: {
      if (!viewable.seasons || !viewable.seasons.length || !viewable.seasons[0].episodes.length) {
        // ToDo proper warning message here
        logger.warn('Show doesn\'t have any seasons or episodes. Skipping', viewable.id, viewable.title);
        return null;
      }

      const firstSeason = viewable.seasons[0].episodes[0];
      const playable = firstSeason.defaultPlayable;

      return {
        ...viewable,
        ...viewableDefaultProps(viewable),
        webviews: extractWebviews(viewable.webview),
        playableId: playable.id,
        rights: defaultRights,
      };
    }

    case VIEWABLE_TYPES.Channel: {
      return {
        ...viewable,
        ...viewableDefaultProps(viewable),
        webviews: extractWebviews(viewable.webview),
        ...(viewable.schedule ? viewable.schedule[0] : {}),
        ...viewable.broadcastById || {},
        channelDescription: viewable.description,
        title: viewable.title, // make sure we use channel title, not broadcast title
        playableId: viewable.id,
        rights: (viewable.playable && viewable.playable.rights) || defaultRights,
        __typename: VIEWABLE_TYPES.Channel,
      };
    }
  }

  return {
    ...viewable,
    ...viewableDefaultProps(viewable),
    webviews: extractWebviews(viewable.webview),
  };
}

function getEpisodeData(seasons: any[] | undefined, playableId: string) {
  if (!seasons) {
    return null;
  }

  return seasons.reduce((acc, season) => {
    if (acc) {
      return acc;
    }

    const episode = season.episodes
      .find((e: any) => e.defaultPlayable?.id === playableId);

    if (!episode) {
      return null;
    }

    return {
      ...episode,
      seasonNumber: season.seasonNumber,
      playableId,
    };
  }, null);
}

export function normalizeWatchViewViewable(originalViewable: any, playableId?: string) {
  const normalizedViewable = normalizeViewable(originalViewable);

  if (!normalizedViewable) {
    return null;
  }

  if (normalizedViewable.duration) {
    normalizedViewable.start = 0;
    normalizedViewable.stop = normalizedViewable.duration;
  }

  normalizedViewable.description = normalizedViewable.description || '';

  const episode = normalizedViewable.seasons && getEpisodeData(
    normalizedViewable.seasons,
    playableId || normalizedViewable.selectedEpisode?.defaultPlayable?.id,
  );
  if (episode) {
    normalizedViewable.playableId = episode.playableId;
    normalizedViewable.duration = episode.duration;
    normalizedViewable.durationHuman = episode.durationHuman;
    normalizedViewable.episodeId = episode.id;
    normalizedViewable.episodeTitle = episode.title;
    normalizedViewable.episodeNumber = episode.episodeNumber;
    normalizedViewable.nextEpisode = episode.nextEpisode;
    normalizedViewable.seasonNumber = episode.seasonNumber;
    normalizedViewable.defaultPlayable = episode.defaultPlayable;
    normalizedViewable.offers = episode.offers;
    normalizedViewable.entitlement = episode.entitlement;

    if (normalizedViewable.duration) {
      normalizedViewable.start = 0;
      normalizedViewable.stop = normalizedViewable.duration;
    }
  }

  return normalizedViewable;
}
