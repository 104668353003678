/* eslint-disable global-require, @typescript-eslint/no-var-requires */
import { Action, Middleware } from 'redux';
import { GraphqlAction, GraphqlResult, GRAPHQL_REQUEST } from 'actions/helpers';
import { AppStore, RootState } from 'reducers';
import { fetchWithTimeout } from 'utils/fetch';
import logger from 'utils/logger';
import { UnauthorizedError } from 'utils/errors';
import { logoutUser } from 'router/utils';

export function createGraphqlMiddleware(
  requestHeaders: Record<string, string>,
): Middleware<unknown, RootState> {
  // parse & validate query in development mode
  let schemaCache: any; // cache schema to avoid parsing it on each request
  function validateQuery(query: string) {
    if (__DEVELOPMENT__) {
      // use require instead of import to not include this module in production mode
      const graphql = require('graphql');
      const graphqlSchema = require('queries/schema.json');

      if (!query) {
        return [
          new Error('query is missing. Please verify the object passed in action creator'),
        ];
      }

      if (!schemaCache) {
        schemaCache = graphql.buildClientSchema(graphqlSchema);
      }

      let queryJSON;
      try {
        queryJSON = graphql.parse(query);
      } catch (e) {
        return [e];
      }

      return graphql.validate(schemaCache, queryJSON);
    }

    return [];
  }

  let agent: any;
  if (__SERVER__) { // reuse existing connections on the server
    const https = require('https');
    agent = new https.Agent({
      maxSockets: 1000,
      keepAlive: true,
    });
  }

  async function processGraphqlAction(store: AppStore, { gql }: GraphqlAction) {
    const state: RootState = store.getState();

    const {
      graphqlEndpoint,
      clientApiToken,
      l10n: { language },
    } = state.settings;

    const {
      isLoggedIn,
    } = state.auth;

    const headers: Record<string, string> = {
      ...requestHeaders,
      'Magine-AccessToken': clientApiToken,
      'Accept-Language': language,
    };

    const { sessionToken } = state.auth;
    if (sessionToken) {
      headers.Authorization = `Bearer ${sessionToken}`;
    }

    const logPrefix = 'GraphQL middleware:';

    const validationErrors = validateQuery(gql.query);
    if (validationErrors.length) {
      logger.error(`${logPrefix} query validation errors:`, ...validationErrors);

      throw new Error('GraphQL query has validation errors');
    }

    const requestOptions = {
      agent,
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify(gql),
    };

    const response = await fetchWithTimeout(graphqlEndpoint, requestOptions)
      .catch((e: { code: number; }) => {
        if (e.code === 20) {
          logger.warn(`${logPrefix} network request aborted, retrying...`);
          return fetchWithTimeout(graphqlEndpoint, requestOptions);
        }

        throw e;
      }).catch((e: any) => {
        if (e instanceof UnauthorizedError && isLoggedIn) {
          logoutUser(store);
        } else {
          logger.error(`${logPrefix} network request failed`, e);
        }

        throw e;
      });

    const contentType = response.headers.get('content-type') || '';
    const isJSONResponse = contentType.includes('application/json');
    const body = isJSONResponse ? await response.json() : await response.text();

    if (!response.ok) {
      logger.error(`${logPrefix} query failed: status ${response.status} ${response.statusText}`, gql, body);

      throw new Error(`GraphQL query failed: status ${response.status} ${response.statusText}`);
    }

    if (!isJSONResponse) {
      logger.error(`${logPrefix} query failed: expected JSON response, got ${contentType}`, gql, body);

      throw new Error(`GraphQL query failed: expected JSON response, got ${contentType}`);
    }

    // just log errors, cause server may return some valuable data besides errors
    const errors = body.errors || [];
    if (errors.length) {
      logger.warn(`${logPrefix} got ${errors.length} errors in response:`, ...errors);
    }

    if (!body.data) {
      logger.warn(`${logPrefix} server returned no data`, gql, body);
    }

    const result: GraphqlResult = {
      data: body.data || {},
      errors,
    };

    return result;
  }

  return store => next => (action: GraphqlAction | Action<string>) => {
    if (action.type !== GRAPHQL_REQUEST) {
      return next(action);
    }

    return processGraphqlAction(store as AppStore, action as GraphqlAction);
  };
}
