import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  ClientSideComponent,
  Text,
} from 'components';
import { ClickTracker } from 'components/Tracking';
import {
  MENU_LINK_TARGET_TYPES,
} from 'utils/constants';
import {
  FooterContainer,
  FooterLink,
} from './FooterStyles';
import FooterImages from './FooterImages';

const getLinkTarget = link => (link.linkTarget === MENU_LINK_TARGET_TYPES.blank ? '_blank' : undefined);

function Footer() {
  const { footerFeature } = useSelector(state => state.settings.features);
  const brandName = useSelector(state => state.settings.brand.name);
  const { footerLinks } = useSelector(state => state.settings);
  const footerImages = useRef();

  useEffect(() => {
    if (!footerImages.current) return;

    // update the footer image container. If all child elements cannot fit in a row, set each section to be 100% width.
    const updateFooterStyle = () => {

      const { children } = footerImages.current;
      let totalWidth = 0;

      // reset inline styles for width and text-align
      for (let child of children) {
        child.style.removeProperty('width');
        child.style.removeProperty('text-align');
      }

      // get column gap size
      const computedStyle = window.getComputedStyle(footerImages.current);
      const columnGap = parseFloat(computedStyle.columnGap) || 0;

      // Loop through all children to calculate the total width including gaps
      for (let i = 0; i < children.length; i++) {
        totalWidth += children[i].offsetWidth;
        if (i < children.length - 1) {
          totalWidth += columnGap; // Add the gap between items
        }
      }

      // Check if children exceed parent's width
      if (totalWidth > footerImages.current.clientWidth) {
        for (let child of children) {
          // update all child to be 100% width if total children width exceed parent's width
          child.style.width = '100%';
          child.style.textAlign = 'center';
        }
      }
    };

    window.addEventListener('resize', updateFooterStyle);
    document.addEventListener('DOMContentLoaded', updateFooterStyle);

    return () => {
      window.removeEventListener('resize', updateFooterStyle);
      document.removeEventListener('DOMContentLoaded', updateFooterStyle);
    };
  }, [footerImages]);

  return (
    <FooterContainer>
      {footerFeature
        && (
        <Box innerRef={footerImages}flexBox wrap spaceBetween mb="medium" columnGap="xlarge">
          { footerFeature.imagesLeft && footerFeature.imagesLeft.length > 0
          && (
            <Box textAlign="left" mr="auto" display="block" alignContent="center">
              {
                footerFeature.imagesLeft.map((oneRow, rowKey) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FooterImages oneRow={oneRow} key={rowKey} />
                ))
              }
            </Box>
          )}

          {footerFeature.imagesCenter && footerFeature.imagesCenter.length > 0
          && (
            <Box column textAlign="center" mx="auto" display="block" alignContent="center">
              {
                footerFeature.imagesCenter.map((oneRow, rowKey) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FooterImages oneRow={oneRow} key={rowKey} />
                ))
              }
            </Box>
          )}

          {footerFeature.imagesRight && footerFeature.imagesRight.length > 0
          && (
            <Box textAlign="right" ml="auto" display="block" alignContent="center">
              {
                footerFeature.imagesRight.map((oneRow, rowKey) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FooterImages oneRow={oneRow} key={rowKey} />
                ))
              }
            </Box>
          )}
        </Box>
        )}
      <Box row wrap mb="fine">
        {footerLinks.map(link => (
          <ClickTracker
            key={link.title}
            component="Settings"
            clickType="navigation"
            context={{ key: link.trackingKey }}
            element="external_links"
          >
            <FooterLink href={link.url} target={getLinkTarget(link)}>
              {link.title}
            </FooterLink>
          </ClickTracker>
        ))}
      </Box>

      <ClientSideComponent>
        <Text
          id="copyrightNotice"
          values={{
            brandName,
            year: new Date().getFullYear(),
          }}
          color="secondary"
          fontSize="fine"
          align="center"
        />
      </ClientSideComponent>
    </FooterContainer>
  );
}

export default React.memo(Footer);
