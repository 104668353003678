import * as Cookies from 'js-cookie';
import {
  AuthActions,
  LOGOUT,
  SET_AUTH,
} from 'actions/auth';

export type AuthReducerState = {
  userId: string,
  sessionToken: string,
  isLoggedIn: boolean,
  isAnonymousUser: boolean,
};

const defaultState: AuthReducerState = {
  userId: typeof Cookies !== 'undefined' ? Cookies.get('userId') || '' : '',
  sessionToken: typeof Cookies !== 'undefined' ? Cookies.get('token') || '' : '',
  isLoggedIn: typeof Cookies !== 'undefined' ? Boolean(Cookies.get('userId') && Cookies.get('token')) : false,
  isAnonymousUser: typeof Cookies !== 'undefined' ? Cookies.get('isAnonymousUser') === 'true' : false,
};

export function authReducer(state = defaultState, action: AuthActions): AuthReducerState {
  switch (action.type) {
    case SET_AUTH: {
      const {
        sessionToken,
        userId,
        isAnonymousUser,
      } = action;

      return {
        sessionToken,
        userId,
        isLoggedIn: Boolean(sessionToken && userId),
        isAnonymousUser,
      };
    }

    case LOGOUT: {
      return defaultState;
    }
  }

  return state;
}
