import createComponent from 'styles/fela/createComponent';
import { BASE_MARGIN } from 'utils/constants';
import {
  responsiveMd,
  responsiveSm,
  responsiveXl,
  smallScreen,
  smallScreenL,
} from 'styles/fela/mixins';

export const Container = createComponent(({ theme, hidePlayer, hoverOn, isMobile }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  minHeight: '700px',
  backgroundColor: theme.color.page,

  extend: [
    smallScreenL({
      minHeight: 0,
    }),
  ],

  '& + #player #hp-controls': {
    display: 'none',
  },

  '& + #player #hp-subtitles': {
    fontSize: '18px',
    position: 'absolute',
    bottom: '32px',       // same as hipster-player #hp-subtitles
  },

  '& + #player': {
    position: 'absolute',
    cursor: 'pointer',
    width: '400px',
    aspectRatio: '16 / 9',
    minHeight: 0,
    height: 'auto',
    borderRadius: theme.radius.default,
    zIndex: theme.zIndex.miniPlayer,
    boxShadow: '0px 0px 24px 3.6px #0000004D',
    transition: 'all 0.3s 0.3s ease-in-out, outline 0s',
    overflow: 'hidden',

    top: '5rem',
    left: BASE_MARGIN.small,
    extend: [
      {
        condition: hidePlayer,
        style: {
          display: 'none',
        },
      },
      {
        condition: hoverOn,
        style: {
          ':hover': {
            outline: '4px white solid',
          },
        },
      },
      smallScreen({
        width: `calc(100vw - ${BASE_MARGIN.small} - ${BASE_MARGIN.small})`,
      }),
      smallScreenL({
        right: BASE_MARGIN.medium,
        bottom: '2.7rem', // total margin of the description
        extend: [
          {
            condition: isMobile,
            style: {
              position: 'fixed',
            },
          },
        ],
      }),
      responsiveSm({
        width: '300px',
        top: 'unset',
        left: 'unset',
        right: BASE_MARGIN.large,
        bottom: '3.7rem', // total margin of the description
      }),
      responsiveMd({
        top: 'unset',
        left: 'unset',
        right: BASE_MARGIN.large,
        bottom: '3.7rem', // total margin of the description
      }),
      responsiveXl({
        bottom: '5.7rem',
      }),
    ],
  },
}), 'div', ['hidePlayer', 'hoverOn', 'isMobile']);

export const BackButton = createComponent(() => ({
  position: 'absolute',
  padding: '10px',
  cursor: 'pointer',
  zIndex: 1,

  '& svg': {
    opacity: 0.6,
  },
  ':hover svg': {
    transform: 'scale(1.2) translateZ(0px)',
    opacity: 1,
  },

  top: '1.2rem',
  marginLeft: '-10px',
  left: BASE_MARGIN.small,
  extend: [
    responsiveSm({
      marginLeft: '-20px',
      left: BASE_MARGIN.medium,
    }),
    responsiveMd({
      top: '3rem',
      marginLeft: '-30px',
      left: BASE_MARGIN.large,
    }),
  ],
}));
