// @ts-nocheck
import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import { ContentListPlayableFragmentFragmentDoc, ViewableTagFragmentFragmentDoc, ContentListEntitlementFragmentFragmentDoc } from './fragments.generated';
import { ContentRatingFragmentFragmentDoc } from './contentRatingFragment.generated';
export type TProgramFragmentFragment = { __typename?: 'Program', genres?: Array<string> | null, description?: string | null, duration?: number | null, durationHuman?: string | null, defaultPlayable?: { __typename?: 'BroadcastPlayable', id: string, kind: Types.TPlayableKind, startTimeUtc: number, watchOffset?: number | null, watched: boolean, duration?: number | null, channel?: { __typename?: 'Channel', title?: string | null, id: string, logoDark?: string | null, logoLight?: string | null } | null, catchup: { __typename?: 'Catchup', from: number, to: number } } | { __typename?: 'ChannelPlayable', id: string, kind: Types.TPlayableKind, duration?: number | null } | { __typename?: 'LiveEventPlayable', id: string, kind: Types.TPlayableKind, startTimeUtc: number, catchupStop?: any | null } | { __typename?: 'VodPlayable', id: string, kind: Types.TPlayableKind, watchOffset?: number | null, watched: boolean, duration?: number | null } | null, tagsToRender?: Array<{ __typename?: 'ViewableTagToRender', title?: string | null, translationKey: string, values: Array<string>, searchable: boolean }> | null, entitlement?: { __typename: 'EntitlementSubscribeType', id?: string | null } | { __typename: 'EntitlementBuyType', id?: string | null } | { __typename: 'EntitlementDefaultType', id?: string | null } | { __typename: 'EntitlementPassType', id?: string | null } | { __typename: 'EntitlementPurchaseType', id?: string | null } | { __typename: 'EntitlementRentType', id?: string | null } | { __typename: 'EntitlementThirdPartyType', id?: string | null } | null, contentRating?: { __typename?: 'ContentRating', name: string, reasons?: Array<string> | null, image?: string | null } | null };

export const ProgramFragmentFragmentDoc = gql`
    fragment ProgramFragment on Program {
  genres
  description
  duration
  durationHuman
  defaultPlayable {
    ...ContentListPlayableFragment
  }
  tagsToRender {
    ...ViewableTagFragment
  }
  entitlement {
    ...ContentListEntitlementFragment
  }
  contentRating {
    ...ContentRatingFragment
  }
}
    ${ContentListPlayableFragmentFragmentDoc}
${ViewableTagFragmentFragmentDoc}
${ContentListEntitlementFragmentFragmentDoc}
${ContentRatingFragmentFragmentDoc}`;