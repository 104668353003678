import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Spacer,
} from 'components';
import { useTrailer } from './TrailerContext';
import {
  IconContainer,
  StyledCircleIcon,
  StyledTrailerIcon,
  Container,
} from './Styles';
import { useSelector } from 'reducers';
import { MOBILE_OS } from 'utils/constants';

function TrailerButton({ short }) {
  const [trailerIsPlaying, playTrailer, viewable] = useTrailer();

  const {
    os,
  } = useSelector(({ common }) => ({
    os: common.os,
  }));
  const isHostedTrailer = !!viewable.trailer;
  const isLinkedTrailer = !!viewable.trailers?.length;
  const isMobile = MOBILE_OS.includes(os);

  if ((!isHostedTrailer && !isLinkedTrailer) || (isLinkedTrailer && isMobile)) {
    return null;
  }

  return (
    <Container
      onClick={playTrailer}
      {...(short ? {
        'data-tooltip-id': 'main',
        'data-tooltip-i18n': 'trailer.watch',
        'data-tooltip-content': 'trailer.watch',
      } : null)}
    >
      <IconContainer>
        <StyledCircleIcon />
        <StyledTrailerIcon inProgress={trailerIsPlaying} />
      </IconContainer>
      {!short && (
        <>
          <Spacer minWidth="medium" />
          <Text fontSize="small" id="trailer.watch" />
        </>
      )}
    </Container>
  );
}

TrailerButton.propTypes = {
  short: PropTypes.bool,
};

export default TrailerButton;
