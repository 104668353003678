import React, { useMemo } from 'react';
import { INFO_DELIMITER } from 'utils/constants';
import { useIsClientSide } from 'utils/hooks';
import {
  renderDuration,
  getStartDateTime,
  getStartAndStopTime,
} from 'utils/helpers';
import {
  ViewableType,
} from 'types';
import { useI18n } from 'components/I18n';
import { RatingImg, RatingText } from './Styles';

type VMetadataProps = {
  viewable: ViewableType | any,
  verticalAlign?: string,
  height?: string,
};

const NO_BREAKING_SPACE = String.fromCharCode(160); // &nbsp;
const RATING_IMG_HEIGHT = 24;

function ViewableMetadata(props: VMetadataProps) {
  const {
    viewable,
    verticalAlign = 'middle',
    height = 'large',
  } = props;
  const i18n = useI18n();
  const isClientSide = useIsClientSide();
  const contentRating = viewable.contentRating || {};
  const ratingText = contentRating.name || viewable?.rating;

  const metadata = useMemo(
    () => {
      const broadcast = viewable.schedule?.[0];
      const seasonsCount = viewable?.seasons?.length || viewable?.show?.seasons?.length;
      const duration = renderDuration(viewable);
      const startTimeUtc = viewable?.defaultPlayable?.startTimeUtc || 0;

      return [
        broadcast && viewable.title,
        viewable?.productionYear,
        getStartDateTime(startTimeUtc * 1000, i18n),
        getStartAndStopTime(viewable),
        seasonsCount ? i18n.formatText('seasonsCount', { seasonsCount }) : duration,
        viewable?.genres?.join(', '),
      ].filter(i => i).join(INFO_DELIMITER);
    },
    [viewable],
  );

  if (!isClientSide) return (<>{NO_BREAKING_SPACE}</>); // reserve space for info - no jumping

  return (
    <>
      {contentRating.image && (
        <>
          <RatingImg
            data-tooltip-id="main"
            data-tooltip-i18n={ratingText}

            src={`${contentRating.image}?height=${RATING_IMG_HEIGHT * 2}`} // double size because image quality
            alt={ratingText}
            height={height}
            verticalAlign={verticalAlign}
          />
          {metadata && INFO_DELIMITER}
        </>
      )}

      {!contentRating.image && ratingText && (
        <>
          <RatingText>{ratingText}</RatingText>
          {metadata && INFO_DELIMITER}
        </>
      )}

      {metadata}
    </>
  );
}

export default React.memo(ViewableMetadata);
