import React from 'react';
import { Box, Text, Heading, CatchupCountdown } from 'components';
import { ChannelType } from 'types';
import { useAdditionalInfo } from 'utils/hooks';
import ViewableMetadata from 'components/ViewableMetadata';

type ChannelTooltipProps = {
  viewable: ChannelType,
};

const ChannelTooltip = ({ viewable }: ChannelTooltipProps): JSX.Element => {
  const broadcast = viewable.schedule?.[0];
  const description = broadcast?.description ?? viewable.description;
  const title = broadcast?.title ?? viewable.title;

  const additionalInfo = useAdditionalInfo(viewable);

  return (
    <>
      <Heading
        fontSize="smedium"
        wordBreak="break-word"
        textNumberLines={2}
        lineHeight="1.5"
      >
        {title}
      </Heading>

      {additionalInfo && (
        <Box>
          <Text textNumberLines={1} fontSize="small" color="videoMetadata">
            <ViewableMetadata viewable={viewable} />
            <CatchupCountdown viewable={viewable} />
          </Text>
        </Box>
      )}

      <Box mt="medium">
        <Text textNumberLines={3} fontSize="small" lineHeight="1.4">
          {description}
        </Text>
      </Box>
    </>
  );
};

export default ChannelTooltip;
