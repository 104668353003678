/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { getApolloContext } from '@apollo/client';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as routerActions from 'router/actions';
import storage from 'utils/storage';
import logger from 'utils/logger';
import * as Sentry from '@sentry/react';
import { PlayerContext } from './PlayerContext';
import playableFragment from './queries/playableFragment.gql';
import { EXIT_FULL_WINDOW_AREA } from 'utils/constants';
import { locationShape } from 'router/prop-types';
import { logout } from 'actions/auth';
import { addMessage } from 'actions/messages';

class PlayerProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    defaultAudioTrack: PropTypes.string.isRequired,
    apiBaseUri: PropTypes.string.isRequired,
    clientApiToken: PropTypes.string.isRequired,
    sessionToken: PropTypes.string.isRequired,
    parentalControl: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    chromecastReceiverId: PropTypes.string,
    withAds: PropTypes.bool.isRequired,
    withAirPlay: PropTypes.bool.isRequired,
    muxKey: PropTypes.string,
    userId: PropTypes.string,
    partner: PropTypes.string,
    location: locationShape.isRequired,
    recommendationSettings: PropTypes.object,
    isLoggedIn: PropTypes.bool.isRequired,
  };

  static contextType = getApolloContext();

  state = {
    player: null,
    playerError: null,

    viewableId: null,
    playableId: null,
    playbackStartTime: 0,
    mini: false,
    fallbackRoute: null,
    isLive: false,
    offsetFromLive: 0,
    isPlayedToEnd: false,
    isPinCodeRequired: false,
    fullPageMode: false,
    hotkeyAction: null,
    refresh: null,
    viewable: null,
    withMiniPlayer: null,
    hasPostroll: false,
    nextVideo: null,
    nextRecommendation: null,
    nextRecommendationActive: false,
    back: null,

    play: async ({
      viewableId,
      playableId,
      refresh,
      viewable,
      playbackStartTime = 0,
      pinCode,
      fullPageMode = false,
      withMiniPlayer,
      nextVideo,
      nextRecommendation,
      back,
    }) => {
      const player = this.getPlayer();

      const {
        clientApiToken,
        sessionToken,
        withAds,
      } = this.props;

      if (fullPageMode) this.disablePageScrolling();

      this.setState({
        playerError: null,
        viewableId,
        playableId,
        refresh,
        viewable,
        playbackStartTime,
        mini: false,
        fallbackRoute: null,
        isLive: false,
        offsetFromLive: 0,
        isPlayedToEnd: false,
        fullPageMode,
        withMiniPlayer,
        nextVideo,
        nextRecommendation,
        nextRecommendationActive: false,
        back,
      });

      await player.loadWithPreflight({
        accessToken: clientApiToken,
        ads: withAds,
        assetId: playableId,
        viewableId,
        authorizationKey: sessionToken,
        playbackStartTime,
        pinCode,
        viewable,
        nextVideo,
        nextRecommendation,
      });
    },

    pause: () => {
      const player = this.getPlayer();

      player.pause();
    },

    stop: () => {
      this.unload();

      this.setState({
        viewableId: null,
        playableId: null,
        playbackStartTime: 0,
        mini: false,
        playerError: null,
        fallbackRoute: null,
        isLive: false,
        offsetFromLive: 0,
        isPlayedToEnd: false,
        refresh: null,
        viewable: null,
        nextVideo: null,
        nextRecommendation: null,
        nextRecommendationActive: false,
      });
    },

    activateMiniPlayer: (fallbackRoute, fullPageMode) => {
      this.enablePageScrolling();
      this.setState((prevState) => {
        const fullPageModeValue = fullPageMode ?? prevState.fullPageMode;
        return { mini: true, fallbackRoute, fullPageMode: fullPageModeValue };
      });
    },

    disablePageScrolling: () => {
      this.disablePageScrolling();
    },

    enablePageScrolling: () => {
      this.enablePageScrolling();
    },

    deactivateMiniPlayer: () => {
      this.disablePageScrolling();
      this.setState({ mini: false, fullPageMode: true });
    },

    toggleNextRecommendation: (value) => {
      this.setState({ nextRecommendationActive: value });
    },

    restoreFromMiniPlayer: () => {
      if (this.state.isPlayedToEnd) {
        this.state.closeMiniPlayer();
      } else {
        this.state.deactivateMiniPlayer();
      }
      if (this.state.fallbackRoute) {
        this.props.push(this.state.fallbackRoute);
      }
    },

    closeMiniPlayer: (stop = true) => {
      if (stop) {
        this.state.stop();
      } else {
        this.setState({ mini: false });
      }
    },

    retryWithPincode: (pinCode) => {
      const {
        play,
        viewableId,
        playableId,
        playbackStartTime,
        refresh,
        viewable,
        fullPageMode,
        withMiniPlayer,
        nextVideo,
        nextRecommendation,
      } = this.state;

      play({
        viewableId,
        playableId,
        playbackStartTime,
        pinCode,
        refresh,
        viewable,
        fullPageMode,
        withMiniPlayer,
        nextVideo,
        nextRecommendation,
      });
    },

    clearPinCodeRequirement: () => {
      this.setState({
        isPinCodeRequired: false,
      });
    },

    deactivateFullScreen: () => {
      const player = this.getPlayer();

      // Exit from full screen mode
      if (player.model.isFullscreen) {
        player._fullscreenService.toggleFullScreen();
      }
    },

    update: (state) => {
      this.setState(state);
    },
  };

  getPlayer() {
    if (this.state.player) {
      return this.state.player;
    }

    const HipsterPlayer = require('@tvoli/hipster-player').default; // eslint-disable-line global-require

    const {
      apiBaseUri,
      defaultAudioTrack,
      parentalControl,
      chromecastReceiverId,
      withAirPlay,
      muxKey,
      userId,
      partner,
      recommendationSettings,
    } = this.props;

    const player = new HipsterPlayer({
      baseUri: apiBaseUri,
      logLevel: __PRODUCTION__ ? 2 : 10,
      chromecast: {
        enabled: !!chromecastReceiverId,
        appId: chromecastReceiverId,
      },
      airplayEnabled: withAirPlay,
      preferredAudio: storage.getItem('audioTrack') || defaultAudioTrack,
      parentalControl,
      muxKey,
      userId,
      partner,
      fullScreenContainer: document.getElementById('playerArea'),
      defaultClosingCreditsOffset: recommendationSettings?.endTimeOffset,
    });

    player.addEventListener('playerInitialized', this.onPlayerInitialized);
    player.addEventListener('chromecastOntimeupdate', this.onChromecastTimeUpdate);
    player.addEventListener('offsetFromLive', this.onOffsetFromLive);
    player.addEventListener('exitFullWindow', this.onExitFullWindow);
    player.addEventListener('viewable:program-changed', this.onProgramChanged);
    player.addEventListener('entitlement:incorrect-pincode', this.onPinCodeRequired);
    player.addEventListener('entitlement:required-pincode', this.onPinCodeRequired);
    player.addEventListener('entitlement', this.onEntitlement);
    player.addEventListener('error', this.onPlayerError);
    player.addEventListener('streamEnded', this.onStreamEnd);
    player.addEventListener('playing', this.onPlaying);
    player.addEventListener('heartbeat', this.onHeartbeat);
    player.addEventListener('adsAsset', this.onAdsAsset);
    player.addEventListener('adsDeactivated', this.onAdsDeactivated);
    player.addEventListener('chromecastPlayNext', this.onChromecastPlayNext);
    player.addEventListener('nextVideo', this.onNextVideo);
    player.addEventListener('closingCreditsStart', this.activateNextRecommendation);
    player.playerContainer.addEventListener('click', this.onPlayerClick);

    window._player = player;
    this.setState({ player });

    return player;
  }

  setHotkeyAction = (action) => {
    this.setState({
      hotkeyAction: {
        action,
        timestamp: Date.now(),
      },
    });
  };

  hotkeysListener = (e) => {
    const { player, mini, restoreFromMiniPlayer, nextRecommendationActive } = this.state;
    const { rights, isPlayingDaiAds, adsPlaying } = player.model;

    if (mini) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        if (player.model.volume === 0) {
          player.changeVolume(Math.min(player.model.activeVideoVolume + 0.05, 1));
        } else {
          player.changeVolume(Math.min(player.model.volume + 0.05, 1));
        }
        this.setHotkeyAction('VOLUMEUP');
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (player.model.volume === 0) {
          player.changeVolume(Math.max(player.model.activeVideoVolume - 0.05, 0));
        } else {
          player.changeVolume(Math.max(player.model.volume - 0.05, 0));
        }
        this.setHotkeyAction(player.model.volume > 0 ? 'VOLUMEDOWN' : 'MUTE');
        break;
      case 'ArrowRight':
        if (
          (
            (isPlayingDaiAds && rights.adsFastForward)
            || (!isPlayingDaiAds && rights.fastForward)
            && !adsPlaying
          ) && !nextRecommendationActive
        ) {
          this.setHotkeyAction('SEEKFORWARD');
        }
        break;
      case 'ArrowLeft':
        if (
          (
            (isPlayingDaiAds && rights.adsRewind)
            || (!isPlayingDaiAds && rights.rewind)
            && !adsPlaying
          ) && !nextRecommendationActive
        ) {
          this.setHotkeyAction('SEEKBACK');
        }
        break;
      case ' ':
        if (
          (
            (isPlayingDaiAds && rights.adsPause)
            || (!isPlayingDaiAds && rights.pause)
            || !player.model.isPlaying
          ) && !nextRecommendationActive
        ) {
          this.setHotkeyAction(player.model.isPlaying ? 'PAUSE' : 'PLAY');
        }
        break;
      case 'f':
      case 'F':
        e.preventDefault();
        if (mini) restoreFromMiniPlayer();
        player._fullscreenService?.toggleFullScreen();
        this.setHotkeyAction(player.model.isFullscreen ? 'FULLSCREENOUT' : 'FULLSCREENIN');
        break;
      case 'm':
      case 'M':
        e.preventDefault();
        player.toggleMute();
        this.setHotkeyAction(player.model.volume > 0 ? 'UNMUTE' : 'MUTE');
        break;
      default:
        break;
    }
  };

  onPlayerInitialized = () => {
    this.setState(({ player }) => ({
      isLive: player.model.isLive,
    }));
    document.body.addEventListener('keydown', this.hotkeysListener);
  };

  unload = () => {
    this.enablePageScrolling();

    const { player } = this.state;
    document.body.removeEventListener('keydown', this.hotkeysListener);
    player.unload();
  };

  onChromecastTimeUpdate = () => {
    this.setState(({ player, isLive }) => {
      if (player.model.isLive === isLive) {
        return null;
      }

      return {
        isLive: player.model.isLive,
      };
    });
  };

  onOffsetFromLive = () => {
    this.setState(({ player }) => ({
      offsetFromLive: player.model.offsetFromLive,
      isLive: player.model.isLive,
    }));
  };

  onExitFullWindow = (props) => {
    if (props.detail.area === EXIT_FULL_WINDOW_AREA.NEXT_BACK_ICON) { // always close without mini
      if (this.state.back) window.history.back();
      this.state.stop();
      return;
    }

    if (props.detail.area === EXIT_FULL_WINDOW_AREA.ICON && this.state.back) {
      window.history.back();
    }

    if (this.state.withMiniPlayer) {
      this.state.activateMiniPlayer(undefined, false);
    } else {
      this.state.stop();
    }
  };

  onProgramChanged = () => {
    this.state.refresh();
  };

  onEntitlement = () => {
    this.setState({
      isPinCodeRequired: false,
    });
  };

  onPinCodeRequired = ({ detail: { isLive, currentTime } }) => {
    this.setState(({ playbackStartTime }) => {
      // save current time for live streams to continue streaming from where stopped
      // check if playbackStartTime is not 0 to avoid overriding it on incorrect PIN
      if (isLive && playbackStartTime === 0) {
        return {
          playbackStartTime: currentTime,
          isPinCodeRequired: true,
        };
      }

      return {
        isPinCodeRequired: true,
      };
    });

    this.unload();
  };

  onPlayerError = (error) => {
    logger.warn('Got Hipster Player error', error);

    const {
      player,
    } = this.state;

    const {
      playerError,
    } = player.model;

    this.unload();

    if (error.code === 401 && this.props.isLoggedIn) {
      this.state.closeMiniPlayer(); // close mini player if 401
      this.props.logout(true);
      this.props.push({ name: 'log-in' }); // Redirect to login
      //do not set error or send error to sentry as user will be redirected to log in page
      return;
    }

    this.setState({
      playerError: player.model.playerError,
    });

    if (error.code === 403) {
      const errorResponse = JSON.parse(error.extra?.response || '{}').error || {};
      if (errorResponse.code === 502) {
        // show error message and refetch viewable when user has no entitlement
        this.state.refresh();
        this.props.addMessage({ contentId: 'playerError.upgradeSubscription' });
      }
    }

    // do not log sentry error if hipster player encounter licence expired
    if (error?.code === 6014) return;

    if (Sentry.getCurrentHub().getClient()) {
      let customError = null;
      if (!(error instanceof Error) && !!error.message && error.action === 'APP_EVENT') {
        customError = new Error(JSON.stringify(error));
        customError.name = error.message;
      }
      Sentry.withScope((scope) => {
        scope.setExtra('playerInfo', {
          ...player.model,
          accessToken: undefined,
          entitlementToken: undefined,
          authorizationKey: undefined,
          headers: undefined,
        });
        scope.setExtra('errorDetails', playerError);
        Sentry.captureException(customError || error);
      });
    }
  };

  isPlayedToEnd = () => {
    if (
      this.props.recommendationSettings // !settings -> Play Next is turned off
      && this.state.nextVideo
      && !this.state.mini
      && !this.state.player.model.isAirPlaying
    ){
      // Play next video
      this.onNextVideo();
      return;
    }
    // close player and refetch viewable if video ends
    this.setState({
      isPlayedToEnd: true,
    });

    this.state.refresh();

    if (this.state.nextRecommendationActive) {
      // Player is staying on Next Recommendation screen
      return;
    }

    this.enablePageScrolling();
    this.deactivateNextRecommendation();
    this.state.deactivateFullScreen();
  };

  onNextVideo = () => {
    this.state.nextVideo?.handler();
  };

  onChromecastPlayNext = (event) => {
    const { detail: { viewableId, playableId } } = event;
    this.props.replace({
      ...this.props.location,
      params: { id: viewableId },
      query: { playableId: playableId },
    });
  };

  onStreamEnd = () => {
    if (!this.state.hasPostroll) {
      this.isPlayedToEnd();
    }
  };

  onPlaying = () => {
    // reset `isPlayedToEnd` when playback is restarted from miniplayer
    if (this.state.isPlayedToEnd) {
      this.setState({
        isPlayedToEnd: false,
      });
    }
  };

  disablePageScrolling = () => {
    document.body.style.overflow = 'hidden';
  };

  enablePageScrolling = () => {
    document.body.style.overflow = '';
  };

  activateNextRecommendation = () => {
    if (!this.state.nextVideo && this.state.nextRecommendation) {
      this.toggleNextRecommendation(true);
    }
  };

  deactivateNextRecommendation = () => {
    this.toggleNextRecommendation(false);
  };

  toggleNextRecommendation = (value) => {
    if (!this.state.mini || !value) {
      this.setState({ nextRecommendationActive: value });
    }
  };

  onPlayerClick = () => {
    if (this.state.nextRecommendation && this.state.nextRecommendationActive) {
      this.deactivateNextRecommendation();
      setTimeout(() => {
        // return focus back to the player
        const { playerControlsContainer } = this.state.player;
        playerControlsContainer.firstElementChild.focus();
      });
    }
  };

  onHeartbeat = async ({ detail: { timestamp } }) => {
    const { client } = this.context;
    const { playableId: id } = this.state;

    client.writeFragment({
      id: `Playable:${id}`,
      fragment: playableFragment,
      data: {
        id,
        watchOffset: timestamp,
      },
    });
  };

  onAdsAsset = async ({ detail: { preroll, postroll } }) => {
    this.setState({
      hasPostroll: !!postroll,
    });
    if (preroll) {
      // if there is preroll then add hotkeys listener as playerInitialized event has not been triggered yet
      document.body.addEventListener('keydown', this.hotkeysListener);
    }
  };

  onAdsDeactivated = async ({ detail: { afterPostroll } }) => {
    if (afterPostroll) {
      this.isPlayedToEnd();
    }
  };

  componentWillUnmount() {
    this.state.player?.unload();
  }

  render() {
    return (
      <PlayerContext.Provider value={this.state}>
        {this.props.children}
      </PlayerContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    settings: {
      clientApiToken,
      apiBaseUri,
    },
    router: {
      location,
    },
  } = state;

  return {
    clientApiToken,
    apiBaseUri,
    location,
    sessionToken: state.auth.sessionToken,
    defaultAudioTrack: state.settings.features.player.defaultAudioTrack,
    parentalControl: !!state.settings.features.parentalControl,
    withAirPlay: !!state.settings.features.airPlay,
    muxKey: state.settings.features.mux?.id,
    adsShowLogo: state.settings.features.ads?.showLogo || false,
    chromecastReceiverId: state.settings.features.chromecast?.receiverId,
    withAds: !!state.settings.features.ads,
    userId: state.auth.userId,
    partner: state.settings.partner,
    recommendationSettings: state.settings.features.playNext?.recommendation,
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = {
  push: routerActions.push,
  replace: routerActions.replace,
  logout: logout,
  addMessage: addMessage,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PlayerProvider);
