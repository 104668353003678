import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Banner, InfoContainer } from '../Styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFela } from 'react-fela';
import { TRACK_AREA, DATA_ID, DATA_VALUE, MOBILE_OS, OS_TYPES } from 'utils/constants';
import { Box, CatchupCountdown, SecondaryButton, Spacer, Text, TVODCountdown } from 'components';
import EventCounter from './EventCounter';
import InfoChannelLogo from './InfoChannelLogo';
import { renderTitle } from '../utils';
import PlayButton from './Buttons/PlayButton';
import { DETAILS_WIDTH, tvodCountdownRules } from './index';
import { ViewableType } from 'types';
import { ENTITLEMENT_TYPES } from 'utils/constants';
import { BackButton, Container } from './Styles';
import Link from 'router/Link';
import { push } from 'router/actions';
import { useAnalytics } from 'components/Tracking';
import ViewableMetadata from 'components/ViewableMetadata';
import { RootState } from 'reducers';
import { useIsSmallScreenLandscape } from 'utils/hooks';

type MuteButtonProps = {
  viewable: ViewableType,
  originalViewable: ViewableType,
  onClose: () => void,
  onInit: () => void,
  hidePlayer: boolean,
  player: any,
  deactivateFullScreen: () => void,
};

const HOVER_DELAY = 700;

function NextInfo({
  viewable,
  originalViewable,
  onClose,
  onInit,
  hidePlayer,
  player,
  deactivateFullScreen,
}: MuteButtonProps) {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const { css } = useFela();

  const {
    isLoggedIn,
    videoPreview,
    recommendationSettings,
    isMobile,
    os,
  } = useSelector((state: RootState) => ({
    isLoggedIn: state.auth.isLoggedIn,
    videoPreview: state.settings.features.videoPreview,
    recommendationSettings: state.settings.features.playNext?.recommendation,
    isMobile: MOBILE_OS.includes(state.common.os),
    os: state.common.os,
  }), shallowEqual);

  const isMobileLandscape = useIsSmallScreenLandscape() && isMobile;

  const [hoverOn, setHoverOn] = useState(false);

  const [countdown, setCountdown] = useState<undefined | number>(recommendationSettings.countdown);
  const interval = useRef<undefined | ReturnType<typeof setInterval>>();

  const params = { id: viewable.id };
  const toInfo = { name: 'watch', params, query: { info: true } };
  const toPlay = { name: 'watch', params, query: { autoplay: true } };

  const isPlayingBeforeDeactivateFullscreeen = useRef(player.model.isFullscreen);

  const stopCountdown = () => {
    if (!interval.current) return;

    clearInterval(interval.current);
    interval.current = undefined;
    setCountdown(undefined);
  };

  const getExtraProps = useCallback((v: ViewableType) => ({
    viewableId: v.id,
    isPlayAvailable: true,
    hasTrailer: !!videoPreview
      && (!!v.trailer || (isLoggedIn && !!v.trailers?.length)),
  }), []);

  const trackClick = useCallback((area: string, v: ViewableType, autoclick = false) => {
    analytics.onClick({
      component: 'NextRecommendation',
      clickType: 'asset',
      eventName: 'click_asset',
      viewableId: v.id,
      extra: {
        ...getExtraProps(v),
        area,
        ...(autoclick ? { autoclick: true } : null),
      },
    });
  }, []);

  useEffect(() => {
    onInit();

    // we need to deactivate full screen on iOS as it will block next recommendation ui
    if (OS_TYPES.IOS === os) {
      isPlayingBeforeDeactivateFullscreeen.current = player.model.isPlaying;
      deactivateFullScreen();
    }

    // send analytics when component is shown
    analytics.onVisible({
      component: 'NextRecommendation',
      viewableId: viewable.id,
      extra: getExtraProps(viewable),
    });

    // send analytics when back to the player
    const onPlayerClick = () => trackClick(TRACK_AREA.miniPlayer, originalViewable);
    player.playerContainer.addEventListener('click', onPlayerClick, true);

    if (countdown) {
      interval.current = setInterval(() => {
        setCountdown((v) => {
          if (v === 1) {
            trackClick(TRACK_AREA.playBtn, viewable, true);
            dispatch(push(toPlay));
            clearInterval(interval.current!);
            interval.current = undefined;
          }
          return v! - 1;
        });
      }, 1000);
    }

    const hoverTimeout = setTimeout(() => setHoverOn(true), HOVER_DELAY);

    return () => {
      stopCountdown();
      clearTimeout(hoverTimeout);
      player.playerContainer.removeEventListener('click', onPlayerClick, true);
    };
  }, []);

  useEffect(() => {
    // on iOS after deactivating fullscreen, video will pause. resume playback if it was playing before deactivating fullscreen
    if (OS_TYPES.IOS === os && isPlayingBeforeDeactivateFullscreeen.current && !player.model.isPlaying && !player.model.isFullscreen) {
      player.playerElement.play();
    }
  }, [player.model.isFullscreen]);

  const backHandler = useCallback(() => {
    trackClick(TRACK_AREA.backBtn, originalViewable);
    onClose();
  }, []);

  const onClickMoreInfo = () => {
    trackClick(TRACK_AREA.infoBtn, viewable);
    dispatch(push(toInfo));
  };

  return (
    <Container
      hoverOn={hoverOn}
      hidePlayer={hidePlayer}
      onMouseMove={interval.current && hoverOn ? stopCountdown : undefined}
      isMobile={isMobile}
    >
      <Banner url={viewable.banner} />

      <BackButton
        onClick={backHandler}
        data-id={DATA_ID.recommendationBtn}
        data-value={DATA_VALUE.backBtn}
      >
        <svg width="11" height="20" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.21172 17.175L0.786718 9.75C0.661718 9.625 0.574219 9.5 0.524219 9.375C0.474219 9.25 0.449219 9.1125 0.449219 8.9625C0.449219 8.8125 0.474219 8.675 0.524219 8.55C0.574219 8.425 0.661718 8.3 0.786718 8.175L8.24922 0.7125C8.47422 0.4875 8.74297 0.375 9.05547 0.375C9.36797 0.375 9.63672 0.4875 9.86172 0.7125C10.0867 0.9375 10.193 1.2125 10.1805 1.5375C10.168 1.8625 10.0492 2.1375 9.82422 2.3625L3.22422 8.9625L9.86172 15.6C10.0867 15.825 10.1992 16.0875 10.1992 16.3875C10.1992 16.6875 10.0867 16.95 9.86172 17.175C9.63672 17.4 9.36172 17.5125 9.03672 17.5125C8.71172 17.5125 8.43672 17.4 8.21172 17.175Z" fill="white" />
        </svg>
      </BackButton>

      <InfoContainer position="absolute">
        <Box height="48vw" md-height="0" maxHeight="70vh" hideEmpty={false} />

        <InfoChannelLogo viewable={viewable} />

        <EventCounter viewable={viewable} />

        <Box mb="medium">
          <Text bold fontSize="large" id="nextRecommendation.label" />
        </Box>

        <Box mb="large" md-width={DETAILS_WIDTH} {...isMobileLandscape && { 'sm-width': DETAILS_WIDTH }}>
          <Text
            as="h1"
            color="primary"
            fontSize="xlarge"
            xl-fontSize="xxxlarge"
            bold
            wordBreak="break-word"
            textShadow="normal"
          >
            {renderTitle(viewable)}
          </Text>
        </Box>

        <Box row justifyContent="start" rowGap="small" wrap>
            <Box width="100%" sm-width="auto" sm-mr="small">
              <PlayButton
                to={toPlay}
                as={Link}
                onPlay={() => trackClick(TRACK_AREA.playBtn, viewable)}
                viewable={viewable}
                disabled={false}
                time={countdown}
                data-id={DATA_ID.recommendationLink}
                data-value={DATA_VALUE.playBtnAutoplay}
              />
            </Box>
            <Box width="100%" sm-width="auto" sm-mr="small" display="flex">
              <SecondaryButton
                variant="white"
                minWidth="auto"
                fullWidth
                sm-fullWidth={false}
                onClick={onClickMoreInfo}
                nowrap
                data-id={DATA_ID.recommendationLink}
                data-value={DATA_VALUE.infoBtnInfo}
              >
                <Text id="featured.moreInfo" />
              </SecondaryButton>
            </Box>
        </Box>

        {!isMobileLandscape && (<>
          {viewable.entitlement && viewable.entitlement.__typename === ENTITLEMENT_TYPES.RENT && (
              <Box mt="medium" className={css(tvodCountdownRules)}>
                <TVODCountdown
                    entitlement={viewable.entitlement}
                />
              </Box>
          )}

          <Spacer height="large" xl-height="giant" />

          <Box mb="medium" md-width={DETAILS_WIDTH}>
            <Text
                textShadow="normal"
                fontSize="normal"
                color="videoMetadata"
                textNumberLines={1}
            >
              <ViewableMetadata viewable={viewable} />
              <CatchupCountdown
                  viewable={viewable}
              />
            </Text>
          </Box>

          <Box md-row md-justifyContent="space-between" lineHeight="1.5em">
            <Box mb="medium" xl-mb="xxlarge" md-width={DETAILS_WIDTH}>
              <Text textShadow="normal" fontSize="normal" textNumberLines={3}>{viewable?.description}</Text>
            </Box>
          </Box>
        </>)}
      </InfoContainer>
    </Container>
  );
}

export default React.memo(NextInfo);
