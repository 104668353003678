import UAParser from 'ua-parser-js';
import { OS_TYPES, BROWSER_TYPES } from 'utils/constants';

export function clearCookies(res) {
  res.clearCookie('token');
  res.clearCookie('userId');

  // API server set this cookies which are equal to token and userId
  // so we should remove them too
  res.clearCookie('mg_session_id');
  res.clearCookie('mg_user_id');
}

// as express@4 doesn't support async middlewares yet,
// we have to catch and propagate async errors manually
export function wrapAsyncMiddleware(asyncMiddleware) {
  return async function asyncMiddlewareWrapper(req, res, next) {
    try {
      await asyncMiddleware(req, res, next);
    } catch (e) {
      next(e);
    }
  };
}

function getOS(agent) {
  const osFamily = (agent.os.name || '').toLowerCase();

  if (!osFamily) {
    return OS_TYPES.OTHER;
  }

  if (osFamily.includes('windows phone')) {
    return OS_TYPES.WINDOWS_PHONE;
  }

  if (osFamily.includes('ios')) {
    return OS_TYPES.IOS;
  }

  if (osFamily.includes('android')) {
    return OS_TYPES.ANDROID;
  }

  if (osFamily.includes('windows')) {
    return OS_TYPES.WINDOWS;
  }

  if (osFamily.includes('linux')) {
    return OS_TYPES.LINUX;
  }

  if (osFamily.includes('mac os') || osFamily.includes('os x')) {
    return OS_TYPES.MAC;
  }

  return OS_TYPES.OTHER;
}

function getBrowser(agent) {
  return Object.values(BROWSER_TYPES).find(bt => agent.browser.name === bt) || BROWSER_TYPES.OTHER;
}

const getDevice = agent => agent.device.type?.toLowerCase();

export function parseUserAgent(userAgentHeader) {
  const agent = UAParser(userAgentHeader);

  return {
    os: getOS(agent),
    device: getDevice(agent),
    browser: getBrowser(agent),
    version: agent.browser.version,
    description: `${agent.browser.name} ${agent.browser.version} / ${agent.os.name} ${agent.os.version}`, // Chrome 15.0.874 / Mac OS X 10.8.1
  };
}
