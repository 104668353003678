import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Helmet } from 'react-helmet-async';
import { useRouter } from 'router';
import { useI18n } from 'components/I18n';
import { RouterLocation } from 'router/location';

// http://ogp.me/#types
export const OpenGraphTypes = {
  website: 'website',
  article: 'article',
  movie: 'video.movie',
  episode: 'video.episode',
  show: 'video.tv_show',
  otherVideo: 'video.other',
};

export const JsonLdTypes = {
  movie: 'Movie',
  show: 'TVSeries',
};

function getFaviconLinks(favicon: any) {
  const items = [];

  for (const [size, url] of Object.entries(favicon || {})) {
    items.push(<link key={size} rel="icon" sizes={size} href={url as any} />);
  }

  if (!items.length) {
    items.push( // just to prevent /favicon.ico requests
      <link key="empty" rel="icon" href="data:," />,
    );
  }

  return items;
}

function prepareDescription(originalDescription: string | string[] | undefined) {
  let description = originalDescription;

  if (Array.isArray(description)) {
    description = description.join('\n');
  }
  description = description?.trim();

  if (!description) {
    return '';
  }

  // https://moz.com/learn/seo/meta-description
  if (description.length > 300) { // trim description
    description = description.substring(0, 301);

    const trimPos = Math.max(
      150, // just a minimum length, so that description is not too short
      description.lastIndexOf('.'),
      description.lastIndexOf('!'),
      description.lastIndexOf('?'),
    );

    description = description.substring(0, trimPos);
    description = `${description}...`;
  }
  return description;
}

type MetadataProps = {
  title?: string,
  description?: string | string[],
  imageUrl?: string,
  type?: typeof OpenGraphTypes[keyof typeof OpenGraphTypes],
  schemaType?: typeof JsonLdTypes[keyof typeof JsonLdTypes],
  canonicalData?: RouterLocation,
};

/**
 * Use this to set html metadata for SEO.
 */
function Metadata({
  canonicalData,
  type,
  schemaType,
  imageUrl,
  ...props
}: MetadataProps) {
  const i18n = useI18n();
  const router = useRouter();

  const {
    locale,
    favicon,
    brandName,
    headerLogo,
    location,
    query,
    shareImage,
    iosAppId,
    googlePlayLink,
    partner,
  } = useSelector((state: RootState) => ({
    locale: state.settings.l10n.language,
    favicon: state.settings.favicon,
    brandName: state.settings.brand.name,
    headerLogo: state.settings.brand.headerLogo,
    location: state.router.location,
    query: state.router.query,
    shareImage: state.settings.localFeatures.shareImage,
    iosAppId: state.settings.appStoreLink?.match(/\/id(\d+)/)?.[1],
    googlePlayLink: state.settings.googlePlayLink,
    partner: state.settings.partner,
  }), shallowEqual);

  const faviconLinks = getFaviconLinks(favicon);

  let title;
  const firstPartOfTitle = i18n.hasMessage(props.title) ? i18n.formatText(props.title) : props.title?.trim();
  if (firstPartOfTitle) {
    title = `${firstPartOfTitle} | ${brandName}`;
  } else {
    title = brandName;
  }

  const prepareImage = () => {
    if (imageUrl) {
      return imageUrl;
    }

    if (shareImage) {
      return `${router.getBaseUrl()}${shareImage}`;
    }

    return headerLogo;
  };

  const description = prepareDescription(props.description);
  const image = prepareImage();

  const {
    url,
    canonicalUrl,
  } = React.useMemo(() => ({
    url: router.getUrl({
      name: location!.name,
      params: location!.params,
      query,
    }),

    // https://moz.com/learn/seo/canonicalization
    canonicalUrl: router.getUrl(canonicalData || {
      name: location!.name,
      params: location!.params,
      query: null,
    }),
  }), [location, query]);

  const jsonLdData = partner === 'magine-pro-demo' && schemaType ? {
    '@context': 'https://schema.org',
    '@type': schemaType,
    'name': title,
    'url': url,
    ...(image && { 'image': image }),
    ...(description && { 'description': description }),
  } : null;

  return (
    <Helmet>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <html lang={locale} prefix="og: http://ogp.me/ns#" />

      {faviconLinks}

      <meta
        name="viewport"
        content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=1.0, initial-scale=1.0"
      />
      <title>{title}</title>

      {/* Google et al. */}
      {description && (
        <meta name="description" content={description} />
      )}

      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph, for Facebook/Twitter/etc. */}
      {/* property="" is for Facebook, name="" is for others https://stackoverflow.com/a/22418095 */}
      <meta
        name="og:type"
        property="og:type"
        content={type || OpenGraphTypes.website}
      />
      <meta
        name="og:title"
        property="og:title"
        content={title}
      />
      <meta
        name="og:site_name"
        property="og:site_name"
        content={brandName}
      />
      {description && (
        <meta
          name="og:description"
          property="og:description"
          content={description}
        />
      )}
      <meta
        name="og:url"
        property="og:url"
        content={url}
      />
      <meta
        name="og:image"
        property="og:image"
        content={image}
      />
      {
        iosAppId && (
          <meta
            name="apple-itunes-app"
            content={`app-id=${iosAppId}`}
          />
        )
      }
      {
        googlePlayLink && (
          <link
            rel="manifest"
            href="/manifest.json"
          />
        )
      }
      {jsonLdData && (
        <script type="application/ld+json">
          {JSON.stringify(jsonLdData)}
        </script>
      )}
    </Helmet>
  );
}

export default React.memo(Metadata);
