// @ts-nocheck
import * as Types from '../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TLogoutAllDevicesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type TLogoutAllDevicesMutation = { __typename?: 'Mutation', logoutAllDevices?: { __typename?: 'LogoutAllDevicesPayload', clientMutationId?: string | null } | null };


export const LogoutAllDevicesDocument = gql`
    mutation logoutAllDevices {
  logoutAllDevices(input: {clientMutationId: ""}) {
    clientMutationId
  }
}
    `;
export type TLogoutAllDevicesMutationFn = Apollo.MutationFunction<TLogoutAllDevicesMutation, TLogoutAllDevicesMutationVariables>;

/**
 * __useLogoutAllDevicesMutation__
 *
 * To run a mutation, you first call `useLogoutAllDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutAllDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutAllDevicesMutation, { data, loading, error }] = useLogoutAllDevicesMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutAllDevicesMutation(baseOptions?: Apollo.MutationHookOptions<TLogoutAllDevicesMutation, TLogoutAllDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TLogoutAllDevicesMutation, TLogoutAllDevicesMutationVariables>(LogoutAllDevicesDocument, options);
      }
export type LogoutAllDevicesMutationHookResult = ReturnType<typeof useLogoutAllDevicesMutation>;
export type LogoutAllDevicesMutationResult = Apollo.MutationResult<TLogoutAllDevicesMutation>;
export type LogoutAllDevicesMutationOptions = Apollo.BaseMutationOptions<TLogoutAllDevicesMutation, TLogoutAllDevicesMutationVariables>;