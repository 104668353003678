import React from 'react';
import { VIEWABLE_TYPES } from 'utils/constants';
import { Box, Text, Heading, CatchupCountdown } from 'components';
import { ViewableType } from 'types';
import { useAdditionalInfo } from 'utils/hooks';
import ViewableMetadata from 'components/ViewableMetadata';

export type VideoTooltipProps = {
  viewable: ViewableType,
};

const VideoTooltip = ({ viewable }: VideoTooltipProps): JSX.Element => {
  const isEpisode = viewable.__typename === VIEWABLE_TYPES.Episode;
  const additionalInfo = useAdditionalInfo(viewable);

  return (
    <>
      <Heading
        fontSize="smedium"
        wordBreak="break-word"
        textNumberLines={2}
        lineHeight="1.5"
      >
        {viewable.show?.title || viewable.title} {isEpisode && (
          <Text
            id="seasonEpisodeShort"
            values={{
              seasonNumber: viewable.seasonNumber,
              episodeNumber: viewable.episodeNumber,
            }}
          />
        )}
      </Heading>

      {additionalInfo && (
        <Box>
          <Text textNumberLines={1} fontSize="small" color="videoMetadata">
            <ViewableMetadata viewable={viewable} />
            <CatchupCountdown viewable={viewable} />
          </Text>
        </Box>
      )}

      <Box mt="medium">
        <Text textNumberLines={3} fontSize="small" lineHeight="1.4">
          {(viewable.description || viewable?.show?.description) ?? ''}
        </Text>
      </Box>
    </>
  );
};

export default React.memo(VideoTooltip);
