export class DownstreamError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
    this.message = message;
    this.name = 'DownstreamError';

    const messageGroups = message.match(/service=\[(.+)], message=\[(.+)]$/);
    this.service = messageGroups[1];
    this.serviceMessage = messageGroups[2];
  }
}

export class ConflictError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
    this.message = message;
    this.name = 'ConflictError';
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super('Unauthorized');
    this.code = 401;
    this.message = 'Unauthorized';
    this.name = 'UnauthorizedError';
  }
}
