import { VIEWABLE_TYPES } from 'utils/constants';

const allowedAdContentType = ['FreeWheel', 'AdServer'];

function extractValue(content) {
  const patterns = [
    { keyword: '_fw_4AID', regex: /<!\[CDATA\[([A-Za-z0-9]+)\]\]>/, errorMsg: 'fw failed match content' },
    { keyword: 'customaid', regex: /customaid="([A-Za-z0-9+-]+)"/, errorMsg: 'customaid failed match content' },
  ];

  for (const { keyword, regex, errorMsg } of patterns) {
    if (content.includes(keyword)) {
      const match = content.match(regex);
      if (errorMsg?.length) console.error(`${errorMsg}: ${content}`);
      return match ? match[1] : '';
    }
  }

  return null;
}



export function extractCustomAdId(ad) {
  const { content, type } = (ad.extensions || [])
    .find(e => allowedAdContentType.includes(e?.type)) || {};  

  if (!content || !type) return '';
  return extractValue(content);
}

export function getMMSData(viewable) {
  // Channel
  if (viewable?.__typename === VIEWABLE_TYPES.Channel) {
    return {
      trackMMS: viewable?.playable.mms,
      mmsOrigCode: viewable?.playable.mmsOrigCode || '',
      assetId: viewable?.broadcastById?.broadcastId,
    };
  }
  // Show/Program/Movie with broadcast playable
  if (viewable?.defaultPlayable?.channel?.playable?.mms) {
    return {
      trackMMS: viewable.defaultPlayable.channel.playable.mms,
      mmsOrigCode: viewable.defaultPlayable.channel.playable.mmsOrigCode || '',
      assetId: viewable.episodeId || viewable.id,
    };
  }

  return {
    trackMMS: false,
    mmsOrigCode: '',
    assetId: undefined,
  };
}
